<template>
  <strong data-tag="prompt">
    <slot></slot>
  </strong>
</template>

<script>
export default {
  name: "prompt"
};
</script>
