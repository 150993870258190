<template>
  <div
    data-tag="testPart"
    :identifier="identifier"
    v-show="showComponent"
    class="testPart"
  >
    <slot></slot>
  </div>
</template>

<script>
import displayMixin from "@/mixins/displayMixin";

export default {
  name: "testPart",

  mixins: [displayMixin],

  props: {
    identifier: String
  },

  data: () => ({
    sectionIds: []
  }),

  methods: {
    parseSections() {
      const items = this.$el.querySelectorAll(
        'div[data-tag="assessmentSection"]'
      );

      for (let i = 0; i < items.length; i++) {
        this.sectionIds.push(items[i].getAttribute("identifier"));
      }
    },

    onNavigation(to) {
      if (to.query.partId === this.identifier) {
        const query = { ...to.query };

        const display = this.$el.querySelector(
          'div[data-tag="crmx"][data-name="nonlinear"][data-value="page"]'
        )
          ? "page"
          : undefined;

        let push = false;
        if (query.display !== display) {
          query.display = display;
          push = true;
        }

        if (!this.sectionIds.includes(query.sectionId)) {
          query.sectionId = this.sectionIds[0];
          push = true;
        }

        if (push) {
          this.$router.replace({ query }).catch(() => {});
        }
      }
    }
  },

  watch: {
    $route(to, from) {
      if (from && to.query.partId === from.query.partId) return;
      this.onNavigation(to);
    }
  },

  mounted() {
    this.parseSections();
    this.onNavigation(this.$route);
  }
};
</script>
<style>
.testPart {
  width: 100%;
  max-width: 750px;
  margin: auto;
  padding: 0px 12px;
}
</style>
