<template>
  <div
    class="py-1"
    data-tag="audioInteraction"
    :data-response-identifier="responseIdentifier"
    style="--plyr-audio-controls-background: #eeeeee"
  >
    <div ref="sections">
      <div v-for="section in sections" :key="section.timestamp">
        <v-layout
          justify-center
          align-center
          grey
          lighten-3
          pa-2
          ma-1
          style="border-radius: 4px"
        >
          <v-btn icon tile :ripple="false" :disabled="readOnly" class="handle">
            <v-icon v-text="icons.mdiDrag"
          /></v-btn>
          <plyr-audio :src="section.data" class="mx-2" style="width: 100%" />
          <v-btn
            icon
            tile
            :ripple="false"
            :disabled="readOnly"
            @click.stop="deleteSection(section.timestamp)"
          >
            <v-icon v-text="icons.mdiDelete" />
          </v-btn>
        </v-layout>
      </div>
    </div>
    <v-alert type="error" :value="error" text>
      <v-layout class="justify-space-between">
        <span v-if="errorMessage" v-text="errorMessage" />
        <span v-else>{{ $t("image_upload_error") }}</span>
        <v-btn
          color="primary"
          tile
          :loading="uploading"
          :ripple="false"
          @click.stop="retry"
        >
          <span v-t="'retry'"></span>
        </v-btn>
      </v-layout>
    </v-alert>
    <v-layout
      justify-center
      align-center
      grey
      lighten-3
      pa-2
      ma-1
      style="border-radius: 4px"
      v-show="!readOnly"
    >
      <v-btn
        icon
        tile
        :ripple="false"
        :disabled="readOnly || uploading || error || state === 'recording'"
        @click.stop="startBtn"
        color="red darken-3"
      >
        <v-icon large v-text="icons.mdiRecordRec" />
      </v-btn>
      <v-btn
        icon
        tile
        :ripple="false"
        :disabled="readOnly || uploading || error || state !== 'recording'"
        @click.stop="pauseBtn"
      >
        <v-icon v-text="icons.mdiPauseCircle" />
      </v-btn>

      <v-layout justify-center align-center>
        <strong style="width: 60px"
          >{{
            Math.floor(stopwatch / 60000)
              .toString()
              .padStart(2, "0")
          }}:{{
            Math.floor((stopwatch / 1000) % 60)
              .toString()
              .padStart(2, "0")
          }}</strong
        >
        <plyr-audio
          :src="objectURL"
          v-if="state !== 'recording' && objectURL"
          class="mx-2"
          style="width: 100%"
        />
      </v-layout>
      <v-btn
        icon
        tile
        :ripple="false"
        :disabled="readOnly || !objectURL || uploading"
        @click.stop="clearCurrent()"
      >
        <v-icon v-text="icons.mdiDelete" />
      </v-btn>
      <v-btn
        icon
        tile
        :loading="uploading"
        :ripple="false"
        :disabled="readOnly || error || state === 'inactive'"
        @click.stop="stopBtn"
      >
        <v-icon v-text="icons.mdiContentSave" />
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";
let fixWebmMetaInfo = null;
const plyrAudio = () =>
  import(/* webpackChunkName: "plyr" */ "./plyrAudio.vue");

import Sortable from "sortablejs";
import {
  mdiPauseCircle,
  mdiRecordRec,
  mdiDelete,
  mdiDrag,
  mdiContentSave
} from "@mdi/js";

import { logger } from "@/helpers";
import { sendMessage } from "../plugins/messaging.js";
const TIMESLICE = 60000;

export default {
  name: "audioInteraction",
  components: {
    plyrAudio
  },

  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],

  data() {
    return {
      state: "inactive",
      //separate objects because I don't want to waste bandwidth downloading files i just uploaded
      sections: [],
      values: [],
      sortable: null,
      recordedChunks: [],
      mediaRecorder: null,
      objectURL: null,
      uploading: false,
      mimeType: "",
      timestamp: null,
      errorMessage: null,
      error: false,
      bypass: false,

      cleanup: null,

      stopwatch: 0,
      interval: null,
      lastInterval: null,

      icons: {
        mdiPauseCircle,
        mdiRecordRec,
        mdiDelete,
        mdiDrag,
        mdiContentSave
      }
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    }
  },

  methods: {
    clearCurrent() {
      if (this.mediaRecorder.state !== "inactive") {
        this.bypass = true;
        this.mediaRecorder.stop();
      }
      const data = {
        responseIdentifier: this.responseIdentifier,
        mimeType: this.mimeType,
        chunk: null,
        state: "deleted"
      };
      sendMessage("AUDIO_INTERACTION", data).catch(failure => {
        logger.warn(failure.message);
        //doesn't matter to user
      });
      this.objectURL = null;
      this.recordedChunks = [];
      this.error = false;
    },
    retry() {
      if (this.mediaRecorder.state === "inactive") {
        const data = {
          responseIdentifier: this.responseIdentifier,
          mimeType: this.mimeType,
          //assume that actual autosave can't fail, just upload
          chunk: null
        };
        return this.onFinish(data);
      }
    },
    parseAttributes() {
      this.required = this.$attrs["data-required"] === "true";
    },
    onFinish(data) {
      data.state = "finished";
      this.uploading = true;
      sendMessage("AUDIO_INTERACTION", data)
        .then(result => {
          if (result) {
            const timestamp = this.timestamp;
            this.values = [
              ...this.values,
              {
                mimeType: this.mimeType,
                data: result.data,
                timestamp: timestamp
              }
            ];

            const blob = new Blob(this.recordedChunks, {
              type: this.mimeType
            });
            if (this.mimeType.startsWith("audio/webm")) {
              fixWebmMetaInfo(blob).then(b => {
                this.sections.push({
                  mimeType: this.mimeType,
                  data: URL.createObjectURL(b),
                  timestamp: timestamp
                });
              });
            } else {
              this.sections.push({
                mimeType: this.mimeType,
                data: URL.createObjectURL(blob),
                timestamp: timestamp
              });
            }
          }
          this.uploading = false;
          this.error = false;
          this.objectURL = null;
          this.recordedChunks = [];
          if (this.cleanup) {
            this.cleanup.resolve();
            this.cleanup = null;
          }
        })
        .catch(failure => {
          logger.warn(failure.message);
          this.errorMessage = failure.message;
          this.error = true;
          this.uploading = false;
          if (this.cleanup) {
            this.cleanup.reject({
              code: failure.code,
              message: failure.message
            });
            this.cleanup = null;
          }
        });
    },
    deleteSection(id) {
      this.values = this.values.filter(v => v.timestamp !== id);
      this.sections = this.sections.filter(v => v.timestamp !== id);
    },
    startBtn() {
      if (!this.mediaRecorder) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(stream => {
            this.recordedChunks = [];
            logger.log("creating recorder");
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.addEventListener("dataavailable", e => {
              logger.log("available", e.data.type);
              if (this.bypass) return;
              this.mimeType = e.data.type;
              const data = {
                responseIdentifier: this.responseIdentifier,
                mimeType: this.mimeType,
                chunk: e.data
              };
              if (e.data.size > 0) {
                this.recordedChunks.push(e.data);
              }
              if ("paused" === this.mediaRecorder.state) {
                const blob = new Blob(this.recordedChunks, {
                  type: this.mimeType
                });
                if (this.mimeType.startsWith("audio/webm")) {
                  fixWebmMetaInfo(blob).then(b => {
                    this.objectURL = URL.createObjectURL(b);
                  });
                } else {
                  this.objectURL = URL.createObjectURL(blob);
                }
              }
              switch (this.mediaRecorder.state) {
                case "inactive":
                  this.onFinish(data);
                  break;
                default:
                  if (e.data.size <= 0) break;
                  data.state = "recording";
                  sendMessage("AUDIO_INTERACTION", data)
                    .then(() => {
                      this.error = false;
                    })
                    .catch(failure => {
                      logger.warn(failure.message);
                      this.errorMessage = failure.message;
                      this.error = true;
                    });
              }
            });
            this.mediaRecorder.addEventListener("stop", () => {
              logger.log("onStop");
              this.$store.commit("setSectionLocked", false);
              clearInterval(this.interval);
              this.stopwatch = 0;
              this.state = this.mediaRecorder.state;
            });
            // ipad won't work if requestData is anywhere before pause is finished
            // old ff will fail if called after pause, just ignore the error
            // everything else will send empty data that will be ignored above
            this.mediaRecorder.addEventListener("pause", () => {
              try {
                this.mediaRecorder.requestData();
                // eslint-disable-next-line no-empty
              } catch {}
            });
            this.mediaRecorder.addEventListener("start", () => {
              logger.log("onStart");
              this.$store.commit("setSectionLocked", true);
              this.lastInterval = new Date();
              this.stopwatch = 0;
              this.interval = setInterval(this.updateStopwatch, 1000);
              this.bypass = false;
              this.recordedChunks = [];
              this.timestamp = new Date().getTime();
              this.state = this.mediaRecorder.state;
            });
            this.mediaRecorder.start(TIMESLICE);
          })
          .catch(err => {
            logger.warn(err);
          });
      } else if (this.mediaRecorder.state === "paused") {
        this.mediaRecorder.resume();
        logger.log("onResume");
        this.$store.commit("setSectionLocked", true);
        this.lastInterval = new Date();
        this.interval = setInterval(this.updateStopwatch, 1000);
        this.state = this.mediaRecorder.state;
      } else {
        this.mediaRecorder.start(TIMESLICE);
      }
    },
    pauseBtn() {
      logger.log("onPause");
      this.$store.commit("setSectionLocked", false);
      clearInterval(this.interval);
      this.updateStopwatch();
      if (this.mediaRecorder.requestData) {
        this.mediaRecorder.requestData();
      }
      this.mediaRecorder.pause();

      this.state = this.mediaRecorder.state;
    },
    stopBtn() {
      this.mediaRecorder.stop();
    },
    updateStopwatch() {
      const now = new Date();
      this.stopwatch += now.getTime() - this.lastInterval.getTime();
      this.lastInterval = now;
    }
  },
  watch: {
    values(value) {
      this.commitValue(value);
    },
    storedValue(v) {
      // if updated from elsewhere
      if (v && v !== this.values) {
        this.values = v;
        this.sections = [...this.values];
      }
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      if (revision.form[this.responseIdentifier]) {
        this.values = revision.form[this.responseIdentifier];
        this.sections = [...this.values];
      } else {
        this.sections = [];
        this.values = [];
      }
    }
  },
  beforeCreate() {
    if (!window.MediaRecorder) {
      import("@/polyfill/audioPoly").then(() => {
        logger.log("loaded audio polyfill");
      });
    }
    if (window.chrome) {
      import(/* webpackChunkName: "webmfix" */ "fix-webm-metainfo").then(f => {
        fixWebmMetaInfo = f.default;
        logger.log("loaded webm meta fix");
      });
    }
  },
  created() {
    this.parseAttributes();
    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.required);
    this.values = this.storedValue || [];
    this.sections = [...this.values];
  },

  mounted() {
    this.sortable = Sortable.create(this.$refs["sections"], {
      handle: ".handle",
      onEnd: ({ oldIndex, newIndex }) => {
        if (oldIndex == newIndex) return;
        let [el] = this.sections.splice(oldIndex, 1);
        this.sections = [
          ...this.sections.slice(0, newIndex),
          el,
          ...this.sections.slice(newIndex)
        ];
        [el] = this.values.splice(oldIndex, 1);
        this.values = [
          ...this.values.slice(0, newIndex),
          el,
          ...this.values.slice(newIndex)
        ];
      }
    });

    this.$store.commit("addCleanupCallback", () => {
      if (
        !this.mediaRecorder ||
        (this.mediaRecorder.state === "inactive" && !this.error)
      ) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        if (this.mediaRecorder.state !== "inactive") {
          this.mediaRecorder.stop();
        }
        //error
        else {
          this.retry();
        }
        this.cleanup = { resolve, reject };
      });
    });
    this.interactionInitialized();
  }
};
</script>
