import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale.replace("_", "-")] = locales(key);
    }
  });
  return messages;
}

const messages = loadLocaleMessages();

export const languages = Object.keys(messages).sort();
const loadDateTimeFormats = () => {
  const dateTimeFormats = {};
  languages.forEach(key => {
    dateTimeFormats[key.replace("_", "-")] = {
      time: { hour: "numeric", minute: "numeric" },
      dateTime: {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        year: "numeric",
        month: "short",
        day: "numeric"
      }
    };
  });
  return dateTimeFormats;
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  dateTimeFormats: loadDateTimeFormats(),
  messages: messages
});

export default i18n;

export const languageText = ({
  languageCode,
  regionCode = undefined,
  locale = undefined
}) => {
  locale = locale || i18n.locale;

  const language = i18n.t(`language.${languageCode}.name`, locale);
  if (regionCode) {
    const region = i18n.t(`language.regions.${regionCode}`, locale);
    return `${language} (${region})`;
  }

  return language;
};
