<template>
  <div
    data-tag="uploadInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <v-dialog :value="editDialog" persistent fullscreen hide-overlay scrollable>
      <v-card tile flat class="upload-dialog">
        <v-toolbar
          color="primary"
          dark
          dense
          flat
          height="38"
          :class="{
            'error darken-1': error
          }"
        >
          <v-btn icon @click="cancelResize" :disabled="loading" depressed light>
            <v-icon color="white" center v-text="icons.mdiClose" />
          </v-btn>

          <v-toolbar-title v-if="error" dark class="toolbar-alert">
            <span v-if="errorMessage">{{ this.errorMessage }}</span>
            <span v-else v-t="'image_upload_error'"></span>
          </v-toolbar-title>

          <v-toolbar-title v-else dark class="toolbar-info">
            <span v-t="'resize_image_help'"></span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn @click="upload" depressed light :loading="loading" small>
            <span v-t="'save'"></span>
          </v-btn>
        </v-toolbar>

        <v-card-text class="editor-card-text">
          <div ref="editor" class="upload-editor">
            <img
              ref="background"
              class="editor-background"
              :src="imageURL"
              @load="backgroundLoaded"
              draggable="false"
              v-if="editDialog"
            />
            <div
              v-if="!inputValue"
              ref="container"
              class="editor-container"
            ></div>
          </div>

          <v-snackbar
            v-if="minScale < 1"
            :value="true"
            :timeout="-1"
            class="zoom-snack"
          >
            <v-layout align-center>
              <v-btn
                icon
                @click="zoom(scale - 0.1)"
                :disabled="scale <= minScale"
              >
                <v-icon v-text="icons.mdiMagnifyMinusOutline" />
              </v-btn>
              <span class="mx-4">{{ Math.floor(scale * 10) * 10 }}%</span>
              <v-btn icon @click="zoom(scale + 0.1)" :disabled="scale >= 1">
                <v-icon v-text="icons.mdiMagnifyPlusOutline" />
              </v-btn>
            </v-layout>
          </v-snackbar>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-alert type="error" :value="error" text>
        <span v-if="errorMessage">{{ this.errorMessage }}</span>
        <span v-else v-t="'image_upload_error'"></span>
      </v-alert>

      <v-file-input
        ref="imageFileInput"
        loading="true"
        v-show="false"
        v-model="fileInput"
        :accept="type ? type : 'image/*'"
        @change="selectFiles"
      />
      <v-card tile flat>
        <div
          v-if="!inputValue"
          class="drag-canvas d-flex flex-column align-center justify-center"
          @drag.stop.prevent
          @dragover.stop.prevent
          @dragstart.stop.prevent
          @dragend.stop.prevent
          @drop="onDrop"
          @paste="onPaste"
        >
          <div><span v-t="'drop_file_here'" /></div>
          <div class="ma-3">
            <v-btn light :loading="loading" @click="action" :disabled="readOnly"
              ><span v-t="'choose_file'"
            /></v-btn>
          </div>
        </div>

        <div v-else>
          <div
            class="d-flex justify-center"
            v-if="preview && inputValue && !editDialog"
          >
            <img
              class="upload-preview"
              :src="inputValue.data"
              draggable="false"
            />
          </div>
          <div v-else-if="!preview && inputValue.data">
            <v-chip outlined label v-text="inputValue.name" />
          </div>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline
} from "@mdi/js";
import { sendMessage } from "../plugins/messaging.js";

import { UploadStage } from "../plugins/uploadStage";

import { logger } from "@/helpers";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

export default {
  name: "uploadInteraction",
  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],

  data: () => ({
    required: undefined,
    loading: false,
    error: false,
    errorMessage: undefined,
    editable: false,
    type: undefined,
    preview: undefined,

    fileInput: undefined,
    image: undefined,
    imageURL: undefined,
    imageWidth: undefined,
    imageHeight: undefined,
    imageOptions: undefined,
    needsScaling: false,
    inputValue: null,

    scale: 1,
    minScale: 0,

    targetWidth: 610,
    targetHeight: 710,

    stage: null,

    icons: {
      mdiClose,
      mdiMagnifyPlusOutline,
      mdiMagnifyMinusOutline
    }
  }),

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    },

    editDialog() {
      if (this.inputValue || !this.editable || !this.imageURL) {
        return false;
      }
      return this.needsScaling;
    }
  },

  methods: {
    parseAttributes() {
      this.required = this.$attrs["data-required"] === "true";
      if (this.$attrs["data-image-options"]) {
        let imageOptions = JSON.parse(this.$attrs["data-image-options"]);
        this.editable =
          imageOptions.editable != null ? imageOptions.editable : false;
        if (imageOptions.max_width) this.targetWidth = imageOptions.max_width;
        if (imageOptions.max_height) {
          this.targetHeight = imageOptions.max_height;
        }
      }
      this.preview = this.$attrs["data-preview"] === "true";
      let types = this.$attrs["type"];
      if (types) {
        this.type = types.replaceAll(" ", ",");
      }
    },
    checkImageSize(width, height) {
      this.imageWidth = width;
      this.imageHeight = height;
      this.scale = 1;
      if (width > this.targetWidth || height > this.targetHeight) {
        this.needsScaling = true;
      } else if (!this.inputValue) {
        this.upload();
      }
    },
    checkFileType(type) {
      if (!type || this.readOnly) return false;
      if (this.type) return this.type.includes(type.toString());
      else return type.toString().indexOf("image") === 0;
    },
    onPaste(event) {
      if (this.loading) return;
      this.error = false;
      if (this.imageURL || this.inputValue) return;
      for (let i = 0; i < event.clipboardData.items.length; i++) {
        const item = event.clipboardData.items[i];
        if (this.checkFileType(item.type)) {
          const blob = item.getAsFile();

          this.imageURL = URL.createObjectURL(blob);
          this.image = blob;
          break;
        }
      }
    },
    onDrop(e) {
      if (this.loading) return;
      e.preventDefault();
      e.stopPropagation();
      this.error = false;
      let files =
        e.target.files && e.taget.files.length > 0
          ? e.taget.files
          : e.dataTransfer.items;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (this.checkFileType(file.type)) {
          this.image = file.getAsFile();
          this.imageURL = URL.createObjectURL(this.image);
          return true;
        }
        if (file.type.endsWith("text/x-moz-url-data")) {
          let context = this;
          file.getAsString(url => {
            let urlRequest = new Request(url);
            fetch(urlRequest)
              .then(res => {
                return res.blob();
              })
              .then(response => {
                context.imageURL = URL.createObjectURL(response);
                context.image = response;
              });
          });
        }
      }
    },

    backgroundLoaded() {
      let width = this.imageWidth;
      let height = this.imageHeight;
      this.minScale = Math.min(
        this.targetHeight / height,
        Math.min(this.targetWidth / width, 1)
      );

      let maxSelectorWidth = Math.min(this.targetWidth, width);
      let maxSelectorHeight = Math.min(this.targetHeight, height);

      let minSelectorWidth = 100;
      let minSelectorHeight = 100;

      let transformDraggable = true;
      this.$refs.editor.style.setProperty("--editor-height", `${height}px`);
      this.$refs.editor.style.setProperty("--editor-width", `${width}px`);
      this.$refs.background.style.setProperty(
        "--background-max-width",
        `${width}px`
      );
      this.$refs.background.style.setProperty(
        "--background-max-height",
        `${height}px`
      );

      if (!this.inputValue) {
        this.stage = new UploadStage({
          container: this.$refs.container,
          width,
          height,
          targetWidth: this.targetWidth,
          targetHeight: this.targetHeight,
          imageWidth: width,
          imageHeight: height,
          maxSelectorWidth,
          maxSelectorHeight,
          minSelectorWidth,
          minSelectorHeight,
          transformDraggable
        });
      }
    },

    selectFiles(files) {
      this.error = false;
      let file;
      if (files == null) return;
      if (Array.isArray(files)) {
        file = files[0];
      } else {
        file = files;
      }
      this.imageURL = URL.createObjectURL(file);
      this.image = file;
    },

    zoom(scale) {
      if (this.loading) return;
      scale = Math.min(Math.max(this.minScale, scale), 1);

      this.$nextTick(() => {
        const width = this.$refs.background.clientWidth;
        const height = this.$refs.background.clientHeight;

        this.$refs.editor.style.setProperty("--editor-height", `${height}px`);
        this.$refs.editor.style.setProperty("--editor-width", `${width}px`);

        this.stage.width = this.$refs.background.clientWidth;
        this.stage.height = this.$refs.background.clientHeight;
      });

      const width = Math.max(this.imageWidth * scale, this.targetWidth);
      const height = this.imageHeight * scale;

      this.$refs.background.style.setProperty(
        "--background-max-width",
        `${width}px`
      );
      this.$refs.background.style.setProperty(
        "--background-max-height",
        `${height}px`
      );
      this.scale = scale;
    },

    cancelResize() {
      this.stageDestroy();
      this.needsScaling = false;
      this.image = null;
      this.imageURL = null;
      this.error = false;
      this.fileInput = null;
    },

    resetSelection() {
      this.imageURL = null;
      this.image = null;
      this.inputValue = null;
      this.fileInput = null;
      this.needsScaling = false;
    },

    async action() {
      if (this.loading) return;
      await this.$refs.imageFileInput.$el.querySelector("input").click();
    },

    async upload() {
      let data = {
        responseIdentifier: this.responseIdentifier,
        name: this.image.name,
        type: this.image.type
      };
      if (this.editDialog) {
        data.resize = {
          width: this.$refs.background.clientWidth,
          height: this.$refs.background.clientHeight
        };
        data.crop = this.stage.getSelectionRect();
      } else if (this.imageWidth) {
        data.resize = {
          width: this.imageWidth,
          height: this.imageHeight
        };
      }

      let reader = new FileReader();
      reader.onload = () => {
        data.arrayBuffer = reader.result;
        this.loading = true;
        if (this.stage) this.stage.stage.listening(false);
        this.error = false;
        sendMessage("UPLOAD_INTERACTION", data, [data.arrayBuffer])
          .then(result => {
            if (result) {
              this.stageDestroy();
              this.imageURL = result.data;
              this.inputValue = { data: result.data, name: result.name };
            }
            this.loading = false;
          })
          .catch(failure => {
            logger.warn(failure.message);
            if (this.stage) this.stage.stage.listening(true);
            this.errorMessage = failure.message;
            this.error = true;
            this.loading = false;
            if (!this.editDialog) this.imageURL = null;
          });
      };
      reader.readAsArrayBuffer(this.image);
    },

    stageDestroy() {
      if (this.stage) {
        this.stage.destroy();
      }

      this.stage = null;
    }
  },

  watch: {
    inputValue(value) {
      this.commitValue(value);
    },
    imageURL(value) {
      if (value && !this.inputValue) {
        if (!this.editable) {
          this.upload();
        } else {
          const i = new Image();
          i.src = this.imageURL;
          i.onload = () => {
            this.checkImageSize(i.width, i.height);
          };
        }
      }
    },
    revision(revision) {
      if (!revision || !revision.form) return;
      this.resetSelection();
      if (revision.form[this.responseIdentifier]) {
        this.inputValue = revision.form[this.responseIdentifier];
        this.imageURL = this.inputValue ? this.inputValue.data : null;
      }
    }
  },

  mounted() {
    this.parseAttributes();
    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.required);

    if (this.storedValue) {
      this.inputValue = this.storedValue;
      this.imageURL = this.storedValue.data;
    }

    this.addClearSelectionCb(this.resetSelection);
    this.interactionInitialized();
  }
};
</script>

<style>
.upload-dialog {
  background: white;
}

.drag-canvas {
  margin: auto;
  display: block;
  border: 0.3em dashed rgba(66, 66, 66, 0.15);
  width: 100%;
  height: 200px;
  background: none rgba(200, 200, 200, 0.25);
  transition: background-color 0.2s;
}

div.editor-wrapper {
  width: 100%;
  padding: 16px;
  background: #fafafa;
}

div.upload-editor {
  position: relative;
  margin: 0 auto;

  width: var(--editor-width);
  height: var(--editor-height);
}

div.editor-container {
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: 5;
}

img.editor-background {
  width: auto;
  height: auto;

  max-width: var(--background-max-width);
  max-height: var(--background-max-height);
  user-select: none;

  display: block;
}
.konvajs-content {
  background: transparent;
}
.zoom-snack .v-snack__wrapper {
  min-width: 0px !important;
}
.toolbar-alert {
  font-size: 16px;
}
.toolbar-info {
  font-size: 16px;
}
.size-check {
  left: -9999px;
  visibility: hidden;
  position: absolute;
}
.upload-preview {
  max-width: 100%;
}
.editor-card-text {
  height: 100%;
  padding: 8px !important;
}
</style>
