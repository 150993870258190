<template>
  <div data-tag="simpleAssociableChoice" v-if="tabular()">
    <slot></slot>
  </div>
  <v-menu
    v-else-if="dragCard()"
    offset-y
    absolute
    close-on-click
    max-width="300px"
    min-width="100px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        :ripple="false"
        :disabled="readOnly"
        v-bind="attrs"
        v-on="acceptableOptions.length > 0 ? on : {}"
        color="grey lighten-2"
        class="choice-container grow ma-1 align-center handle"
        :class="checkRowMax ? 'd-flex' : 'd-none'"
        draggable
        @dragstart.stop="onDragStart"
        @dragend.stop="onDragEnd"
      >
        <v-icon class="pr-1" v-text="icons.mdiDrag" />
        <div
          class="white d-flex align-center justify-center"
          style="width:100%"
        >
          <div ref="slotData" data-slot-data style="width:100%">
            <slot></slot>
          </div>
          <speech-synthesis-btn :text="text" v-if="text != ''" class="white" />
        </div>
      </v-card>
    </template>

    <v-list dense>
      <v-list-item
        link
        v-for="(option, index) in acceptableOptions"
        :key="index"
        class="menu-list-item"
      >
        <v-list-item-content @click="onMenuClick(identifier, option.identifier)"
          ><div class="d-flex align-center">
            <span v-text="option.text" />
            <speech-synthesis-btn
              :text="option.text"
              v-if="option.text != ''"
            />
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
  <!--------------------------------------------->
  <v-card
    v-else
    class="option-card grow d-flex flex-column align-center justify-start"
    :class="{
      'option-error': optionError()[identifier]
    }"
    color="grey lighten-2"
    @drag.stop.prevent
    @dragover.stop.prevent
    @dragstart.stop.prevent
    @dragend.stop.prevent
    @drop.stop.prevent="
      e => {
        (hover = false), onDrop(identifier, e);
      }
    "
  >
    <div
      class="d-flex align-center justify-center grey lighten-4"
      style="width:100%"
    >
      <div ref="slotData" data-slot-data style="width:100%">
        <slot></slot>
      </div>
      <speech-synthesis-btn :text="text" v-if="text != ''" />
    </div>
    <div
      class="option-children grow"
      :data-not-maxed="checkOptionMax"
      :data-drop-enabled="dropEnabled()"
      :data-hover-enabled="dropEnabled() && hover"
      @dragleave.stop.prevent="hover = false"
      @dragenter.stop.prevent="hover = true"
    >
      <div
        class="choice-card align-center white d-flex  align-center justify-center"
        v-for="match in storedValue.filter(v => v.optionId === identifier)"
        :key="match.rowId"
      >
        <div v-html="getRowMatchContent(match.rowId)" style="width:100%" />

        <v-btn @click.stop="onClickRemove(match.rowId, identifier)" icon>
          <v-icon center v-text="icons.mdiClose" />
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";
import { mdiDrag, mdiClose } from "@mdi/js";
export default {
  name: "simpleAssociableChoice",
  inject: [
    "dragCard",
    "responseIdentifier",
    "rows",
    "options",
    "optionError",
    "onDrop",
    "onMenuClick",
    "onClickRemove",
    "setDragEvent",
    "getDragEvent",
    "tabular"
  ],

  data: () => ({
    matchMax: 0,
    matchMin: 0,
    identifier: null,
    hover: false,
    icons: {
      mdiDrag,
      mdiClose
    },
    text: ""
  }),

  components: {
    speechSynthesisBtn
  },
  computed: {
    acceptableOptions() {
      return this.options().filter(
        v =>
          !this.storedValue.find(
            vv => vv.optionId === v.identifier && vv.rowId === this.identifier
          ) &&
          (!v.matchMax ||
            v.matchMax >
              this.storedValue.filter(vv => vv.optionId === v.identifier)
                .length)
      );
    },
    checkRowMax() {
      return (
        this.matchMax === 0 ||
        this.matchMax >
          this.storedValue.filter(v => v.rowId === this.identifier).length
      );
    },
    checkOptionMax() {
      return (
        this.matchMax === 0 ||
        this.matchMax >
          this.storedValue.filter(v => v.optionId === this.identifier).length
      );
    },
    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    },
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier) || [];
    }
  },
  methods: {
    dropEnabled() {
      const e = this.getDragEvent();
      if (!this.checkOptionMax || !e) return false;
      const id = e.dataTransfer.getData("text");
      return !this.storedValue.find(
        v => v.rowId === id && v.optionId === this.identifier
      );
    },
    onDragStart(e) {
      e.dataTransfer.setData("text", this.identifier);
      this.setDragEvent(e);
    },
    onDragEnd() {
      this.setDragEvent(null);
    },
    getRowMatchContent(rowId) {
      const row = this.rows().find(v => v.identifier === rowId);
      return row ? (row.html ? row.html : "") : "";
    }
  },
  created() {
    if (this.$attrs["match-max"]) {
      this.matchMax = parseInt(this.$attrs["match-max"], 10);
    }
    if (this.$attrs["match-min"]) {
      this.matchMin = parseInt(this.$attrs["match-min"], 10);
    }
    this.identifier = this.$attrs["identifier"];
  },
  mounted() {
    this.text = this.$refs["slotData"].innerText;
  }
};
</script>
<style>
.menu-list-item img.crmx-asociateoption-img {
  display: none;
}
</style>
<style scoped>
.choice-card {
  margin-top: 4px;
  padding: 2px;
  min-width: 50px;
  width: 100%;
  text-align: center;
}
.choice-card:first-child {
  margin-top: 0px;
}
.choice-container {
  max-width: 30%;
  padding: 2px;
  min-width: 50px;
  text-align: center;
}
.option-card {
  text-align: center;
  border: thin solid !important;
  min-width: 80px;
  max-width: 30%;
  min-height: 100px;
  margin: 4px;
}

.option-card.option-error {
  border-color: #ff5252 !important;
}
.option-children {
  width: 100%;
  min-height: 80px;
}
.option-children[data-not-maxed="true"] {
  border: 0.2em dashed grey !important;
}
.option-children[data-drop-enabled="true"] div {
  visibility: hidden;
}
.option-children[data-not-maxed="true"][data-hover-enabled="true"] {
  border: 0.2em dashed rgb(0, 200, 0) !important;
}
</style>
