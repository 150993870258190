// https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
const mode = process.env.VUE_APP_MODE || process.env.NODE_ENV;

export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const logger = {
  log(...args) {
    if (["production", "test"].indexOf(mode) === -1) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  },
  warn(...args) {
    if (["production", "test"].indexOf(mode) === -1) {
      // eslint-disable-next-line no-console
      console.warn(...args);
    }
  }
};

// Debounce function with delay and a max limit before it must be executed.
export const debounce = (func, delay, limit) => {
  let inDebounce;
  let lastCall = new Date();

  return {
    // Call the debounced function.
    call(...args) {
      const context = this;
      clearTimeout(inDebounce);

      if (inDebounce !== undefined && Math.abs(new Date() - lastCall) > limit) {
        // Handle cases where the debounced function gets called repeatedly
        // before the delay and thus never actually executes the debounced function.
        // The limit sets the maximum wait until it will immediately call the
        // debounced function.
        lastCall = new Date();
        func.apply(context, args);
      } else {
        if (inDebounce === undefined) {
          // First call since the last execution, set last execution to now so
          // the limit can be applied correctly.
          lastCall = new Date();
        }

        inDebounce = setTimeout(() => {
          inDebounce = undefined;
          lastCall = new Date();
          func.apply(context, args);
        }, delay);
      }
    },

    // Clear any awaiting calls that have been debounced.
    clear() {
      clearTimeout(inDebounce);
      inDebounce = undefined;
    }
  };
};

export default {
  shuffleArray,
  logger
};
