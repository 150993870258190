import { logger } from "@/helpers";

const callbacks = {};
const handlers = {};
let messagePort;

const defaultTimeout = 60000;

const _postMessage = (
  type,
  messageId = undefined,
  data = undefined,
  name = undefined,
  transferList
) => {
  const payload = {
    type: type,
    name: name,
    data: data,
    messageId: messageId
  };
  logger.log("qti-frontend/_postMessage", payload);
  messagePort.postMessage(payload, transferList);
};

const resolveMessage = (messageId, data = undefined) => {
  if (messageId === undefined) return;
  _postMessage("RESOLVE", messageId, data, "RESOLVE");
};

const rejectMessage = (messageId, error) => {
  if (messageId === undefined) return;
  _postMessage("REJECT", messageId, error, "REJECT");
};

export const sendMessage = async (
  name,
  data = undefined,
  transferList = undefined,
  timeout = undefined
) => {
  if (!messagePort) {
    return Promise.reject({
      code: "message_port_error",
      message: "message port not initialized"
    });
  }

  if (timeout === 0) {
    _postMessage("MESSAGE", undefined, data, name, transferList);
    return Promise.resolve();
  }

  if (timeout === undefined) {
    timeout = defaultTimeout;
  }

  return new Promise((resolve, reject) => {
    const messageId = `${Math.floor(Math.random() * 1000000000)}`;

    const timeoutId = setTimeout(() => {
      if (callbacks[messageId]) {
        callbacks[messageId].reject({
          code: "timeout_error",
          message: "message timeout"
        });
        delete callbacks[messageId];
      }
    }, timeout);
    callbacks[messageId] = { resolve, reject, timeoutId };
    _postMessage("MESSAGE", messageId, data, name, transferList);
  });
};

export const syncMessage = async (
  name,
  data = undefined,
  transferList = undefined
) => {
  return sendMessage(name, data, transferList, 0);
};

const addEventListener = (name, handler) => {
  if (!handlers[name]) handlers[name] = [];
  handlers[name].push(handler);
};

const removeEventListener = (name, handler) => {
  if (!handlers[name]) return;
  handlers[name] = handlers[name].filter(v => v != handler);
};

const messageHandler = event => {
  logger.log("qti-frontend/messageHandler", event);

  if (event.data === "CHANNEL" && event.ports.length > 0) {
    messagePort = event.ports[0];
    messagePort.onmessage = messageHandler;
  }

  let type = event.data.type;
  let name = event.data.name;
  switch (type) {
    case "REJECT":
      if (callbacks[event.data.messageId]) {
        clearTimeout(callbacks[event.data.messageId].timeoutId);
        callbacks[event.data.messageId].reject(event.data.data);
      }
      delete callbacks[event.data.messageId];
      break;
    case "RESOLVE":
      if (callbacks[event.data.messageId]) {
        clearTimeout(callbacks[event.data.messageId].timeoutId);
        callbacks[event.data.messageId].resolve(event.data.data);
      }
      delete callbacks[event.data.messageId];
      break;
  }

  if (!name) return;
  if (handlers[name]) for (const h of handlers[name]) h(event);
};

export default {
  syncMessage,
  sendMessage,
  messageHandler,
  addEventListener,
  resolveMessage,
  rejectMessage,
  removeEventListener
};
