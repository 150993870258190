var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matchInteraction",attrs:{"data-tag":"matchInteraction","data-response-identifier":_vm.responseIdentifier}},[(_vm.tabular)?_c('table',[_c('tr',[_c('th',{staticStyle:{"text-align":"left"},domProps:{"textContent":_vm._s(_vm.promptText)}}),_vm._l((_vm.options),function(option){return _c('th',{key:option.text},[_c('span',{domProps:{"textContent":_vm._s(option.text)}}),_c('speech-synthesis-btn',{staticClass:"visible",attrs:{"text":option.text}})],1)})],2),_vm._l((_vm.rows),function(row){return _c('tr',{key:row.identifier},[_c('td',{staticClass:"matchInteractionLabel"},[_c('span',{domProps:{"textContent":_vm._s(row.text)}}),_c('speech-synthesis-btn',{attrs:{"text":row.text}})],1),_vm._l((_vm.options),function(option){return _c('td',{key:option.identifier},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(_vm.optionType === 'radio')?_c('v-radio',{ref:row.identifier,refInFor:true,staticClass:"ma-0 pa-0",class:{
              'radio-error':
                _vm.rowError[row.identifier] || _vm.optionError[option.identifier]
            },attrs:{"name":row.identifier,"value":option.identifier,"data-option":option.identifier,"disabled":_vm.readOnly,"hide-details":""},nativeOn:{"!click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onInput(row.identifier, option.identifier)}}}):_c('v-checkbox',{ref:row.identifier,refInFor:true,staticClass:"ma-0 pa-0",attrs:{"error":_vm.rowError[row.identifier] || _vm.optionError[option.identifier],"disabled":_vm.readOnly,"input-value":_vm.values.some(
                v =>
                  v.rowId === row.identifier &&
                  v.optionId === option.identifier
              ),"hide-details":""},nativeOn:{"!click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onInput(row.identifier, option.identifier)}}})],1)])})],2)})],2):_c('div',{ref:"slotData",staticClass:"d-flex justify-space-around qti-ordering slotted-data",attrs:{"flat":""}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }