<template>
  <v-btn
    v-if="speechSynthesis"
    @click.stop.prevent="synthesize"
    small
    icon
    class="speech-synthesis mx-1"
  >
    <v-icon small v-text="icons.mdiAccountVoice" />
  </v-btn>
</template>

<script>
import { mdiAccountVoice } from "@mdi/js";
import { sendMessage } from "../plugins/messaging.js";

export default {
  name: "speech-synthesis-btn",

  props: {
    text: String
  },

  data: () => ({
    icons: Object.freeze({
      mdiAccountVoice
    })
  }),

  computed: {
    speechSynthesis() {
      return this.$store.getters.speechSynthesis;
    }
  },

  methods: {
    synthesize() {
      sendMessage("SYNTHESIZE_TEXT", {
        text: this.text !== null ? this.text.trim() : ""
      });
    }
  }
};
</script>

<style>
div.speech-synthesis {
  display: inline-block;
}
</style>
