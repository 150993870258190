<template>
  <v-app style="background-color: #fafafa;">
    <v-main>
      <v-container class="pa-0" fluid>
        <template v-if="!templateCompiled">
          <div style="width: 100%; max-width: 726px; margin:auto">
            <v-skeleton-loader type="heading" class="mt-6" />
            <v-skeleton-loader
              type="text"
              class="mt-2"
              style="max-width: 250px;"
            />
            <v-skeleton-loader
              type="card-heading, card-text@4"
              class="mt-4 elevation-1"
            />
            <v-skeleton-loader
              type="card-heading, card-text@4"
              class="mt-4 elevation-1"
            />
            <v-skeleton-loader
              type="card-heading, card-text@4"
              class="mt-4 elevation-1"
            />
          </div>
        </template>
        <router-view />
      </v-container>
    </v-main>
    <v-navigation-drawer
      app
      right
      stateless
      fixed
      :temporary="$route.query.display !== 'page'"
      overlay-opacity="0.1"
      @input="revisionInput"
      :value="revisionDialog"
    >
      <revisions-drawer :value="revisionDialog" @activated="activateRevision" />
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { allowedOrigins } from "@/debug";
import messaging from "@/plugins/messaging";
import { logger } from "@/helpers";

export default {
  name: "App",

  data() {
    return {};
  },
  computed: {
    revisionDialog() {
      return this.$store.getters.revisionDialog;
    },
    readOnly() {
      return this.$store.getters.readOnly;
    },
    templateCompiled() {
      return this.$store.getters.templateCompiled;
    },
    responseValues() {
      return this.$store.getters.responseValues;
    },
    otherValues() {
      return this.$store.getters.otherValues;
    },
    bookmarks() {
      return this.$store.getters.bookmarks;
    },
    metadata() {
      return this.$store.getters.metadata;
    },
    valid() {
      return this.$store.getters.valid;
    },
    allowSyncValues() {
      return !this.revisionDialog && !this.readOnly && this.templateCompiled;
    }
  },

  methods: {
    onError(error) {
      if (
        this.$route.path.includes("/local/") ||
        this.$route.path.includes("/remote/")
      ) {
        logger.warn(error);
        return;
      }
      this.$router
        .replace({
          name: "error",
          params: {
            forced: true,
            error: error
          }
        })
        .catch(() => {});
    },
    activateRevision() {
      if (!this.readOnly) {
        const promises = [
          messaging.sendMessage(
            "VALUES",
            { values: this.responseValues },
            undefined,
            5000
          ),
          messaging.sendMessage(
            "OTHER_VALUES",
            {
              otherValues: this.otherValues
            },
            undefined,
            5000
          ),
          messaging.sendMessage("METADATA", { metadata: this.metadata }),
          messaging.sendMessage("VALID", { valid: this.valid })
        ];
        Promise.all(promises).catch(error => {
          this.onError(error ? error.message : "");
        });
      }
    },
    revisionInput(e) {
      if (this.revisionDialog != e) {
        this.$store.commit("setRevisionDialog", e);
      }
    },
    onStart(event) {
      const data = event.data.data;

      this.$store.commit("setToken", data.token);
      this.$store.commit("setModuleType", data.moduleType);
      const locale = data.locale || "en";
      this.$i18n.locale = locale;

      if (data.a11y) {
        if (data.a11y.backgroundColor) {
          document.body.classList.add(
            `a11y-bg-${data.a11y.backgroundColor.slice(1)}`
          );
        }

        if (data.a11y.speechSynthesis) {
          this.$store.commit("setSpeechSynthesisEnabled", true);
        }
        this.$store.commit(
          "setSpeechRecognitionLanguage",
          data.a11y.speechRecognitionLanguage
        );
        this.$store.commit(
          "setSpellCheckLanguage",
          data.a11y.spellCheckLanguage
        );
      }

      this.$store.commit("setHidden", !!data.hidden);

      if (data.values) {
        this.$store.commit("setResponseValues", data.values);
      }
      if (data.otherValues) {
        this.$store.commit("setOtherValues", data.otherValues);
      }
      if (data.bookmarks) {
        this.$store.commit("setBookmarks", data.bookmarks);
      }
      if (data.allowPrint) {
        this.$store.commit("setAllowPrint", true);
      }

      this.$router.push({
        name: "assessment",
        params: {
          package: data.package,
          identifier: data.identifier
        }
      });
    },

    onSubmission(event) {
      /**
       * Some interactions autosaves content in one format and
       * have their submission data in another format.
       */
      const values = { ...this.responseValues };

      const interactionElements = this.$el.querySelectorAll(
        '[data-submission-values="true"]'
      );
      for (let i = 0; i < interactionElements.length; i++) {
        const identifier = interactionElements[i].getAttribute(
          "data-response-identifier"
        );

        values[identifier] = interactionElements[i].__vue__.submissionData();
      }
      const messageId = event.data.messageId;
      messaging.resolveMessage(messageId, { values: values });
    },

    onAudioSave(event) {
      const data = event.data.data;
      const oldValue =
        this.$store.getters.responseValue(data.responseIdentifier) || [];
      const value = [
        ...oldValue,
        {
          mimeType: data.mimeType,
          data: data.data,
          timestamp: new Date().getTime()
        }
      ];
      this.$store.commit("setResponseIdentifierValue", {
        responseIdentifier: data.responseIdentifier,
        value: value
      });
    },

    onStop(event) {
      const messageId = event.data.messageId;

      const promises = [];
      this.$store.getters.cleanupCallbacks.forEach(c => promises.push(c()));
      Promise.allSettled(promises).then(results => {
        const err = results.find(r => r.status === "rejected");
        if (err) messaging.rejectMessage(messageId, err.reason);
        else {
          messaging.resolveMessage(messageId);
        }
      });
    }
  },

  watch: {
    responseValues(values) {
      if (this.allowSyncValues) {
        messaging
          .sendMessage("VALUES", { values: values }, undefined, 5000)
          .catch(error => {
            this.onError(error ? error.message : "");
          });
      }
    },

    otherValues(values) {
      if (this.allowSyncValues) {
        messaging
          .sendMessage("OTHER_VALUES", { otherValues: values }, undefined, 5000)
          .catch(error => {
            this.onError(error ? error.message : "");
          });
      }
    },

    bookmarks(values) {
      if (this.allowSyncValues) {
        messaging.sendMessage("BOOKMARKS", { bookmarks: values });
      }
    },

    metadata(metadata) {
      if (this.allowSyncValues) {
        messaging.sendMessage("METADATA", { metadata: metadata });
      }
    },
    valid(valid) {
      if (this.allowSyncValues) {
        messaging.sendMessage("VALID", { valid: valid }).catch(error => {
          this.onError(error ? error.message : "");
        });
      }
    }
  },
  created() {
    window.addEventListener("keydown", event => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault();
        messaging.syncMessage("PRINT");
      }
    });

    window.addEventListener("beforeprint", () => {
      messaging.syncMessage("PRINT");
    });

    messaging.addEventListener("START", this.onStart);
    messaging.addEventListener("AUDIO_RECOVERED", this.onAudioSave);
    messaging.addEventListener("STOP", this.onStop);
    messaging.addEventListener("SUBMISSION_VALUES", this.onSubmission);
    window.addEventListener("message", event => {
      if (allowedOrigins.includes(event.origin)) {
        messaging.messageHandler(event);
      }
    });

    messaging.addEventListener("HIDDEN", event => {
      if (event && event.data && event.data.data) {
        this.$store.commit("setHidden", !!event.data.data.hidden);
      }
    });
  },
  mounted() {
    if (window.innerHeight === 0) {
      logger.log("starting from hidden");
      const listener = () => {
        if (window.innerHeight !== 0) {
          this.$store.commit("setHiddenInit", true);
          window.removeEventListener("resize", listener);
        }
      };
      window.addEventListener("resize", listener);
    }
  }
};
</script>

<style>
html {
  overflow: auto !important;
}

.v-card__text,
.v-card__title {
  word-break: normal;
}

div[data-tag="assessmentTest"] {
  padding: 0px;
}

/** Custom background color */

.a11y-bg-ffddf4 .assessmentItem, .a11y-bg-ffddf4 .assessmentItem .theme--light.v-card {
  background: #ffddf4 !important;
}

.a11y-bg-e4e2e0 .assessmentItem, .a11y-bg-e4e2e0 .assessmentItem .theme--light.v-card {
  background: #e4e2e0 !important;
}

.a11y-bg-f9dede .assessmentItem, .a11y-bg-f9dede .assessmentItem .theme--light.v-card {
  background: #f9dede !important;
}

.a11y-bg-dce4ef .assessmentItem, .a11y-bg-dce4ef .assessmentItem .theme--light.v-card {
  background: #dce4ef !important;
}

.a11y-bg-e1f3f8 .assessmentItem, .a11y-bg-e1f3f8 .assessmentItem .theme--light.v-card {
  background: #e1f3f8 !important;
}

.a11y-bg-e7f4e4 .assessmentItem, .a11y-bg-e7f4e4 .assessmentItem .theme--light.v-card {
  background: #e7f4e4 !important;
}

.a11y-bg-fff1d2 .assessmentItem, .a11y-bg-fff1d2 .assessmentItem .theme--light.v-card {
  background: #fff1d2 !important;
}

.a11y-bg-fad980 .assessmentItem, .a11y-bg-fad980 .assessmentItem .theme--light.v-card {
  background: #fad980 !important;
}

.a11y-bg-ffddf4 .fr-wrapper {
  background: #ffddf4 !important;
}
.a11y-bg-e4e2e0 .fr-wrapper {
  background: #e4e2e0 !important;
}

.a11y-bg-f9dede .fr-wrapper {
  background: #f9dede !important;
}

.a11y-bg-dce4ef .fr-wrapper {
  background: #dce4ef !important;
}

.a11y-bg-e1f3f8 .fr-wrapper {
  background: #e1f3f8 !important;
}

.a11y-bg-e7f4e4 .fr-wrapper {
  background: #e7f4e4 !important;
}

.a11y-bg-fff1d2 .fr-wrapper {
  background: #fff1d2 !important;
}

.a11y-bg-fad980 .fr-wrapper {
  background: #fad980 !important;
}

/** Text to speech buttons */

@media (hover: hover) {
  .speech-synthesis {
    visibility: hidden;
  }

  .speech-synthesis:focus {
    visibility: visible !important;
  }

  .visible.speech-synthesis,
  .v-list-item:hover .speech-synthesis,
  .inlineChoiceInteraction:hover .speech-synthesis,
  .assessment-title:hover .speech-synthesis,
  .assessmentSection h1:hover .speech-synthesis,
  div.simpleChoice:hover .speech-synthesis,
  td.matchInteractionLabel:hover .speech-synthesis,
  div.textEntryInteraction:hover .speech-synthesis,
  .extendedTextInteraction:hover .speech-synthesis,
  .choice-container:hover .speech-synthesis,
  .option-card:hover .speech-synthesis,
  p.crmx-description:hover .speech-synthesis {
    visibility: visible !important;
  }
}
</style>
