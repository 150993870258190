import Vue from "vue";
import vuetify from "./plugins/vuetify";

import store from "./store";
import router from "./router";

import App from "./App";

import crmx from "./components/crmx.vue";

const programmingInteraction = () =>
  import(
    /* webpackChunkName: "codemirror" */
    "./components/programmingInteraction.vue"
  );
import customInteraction from "./components/customInteraction.vue";
import revisionsDrawer from "./components/revisionsDrawer.vue";
import assessmentTest from "./components/assessmentTest.vue";
import testPart from "./components/testPart.vue";
import fullscreenTestPart from "./components/fullscreenTestPart.vue";
import assessmentSection from "./components/assessmentSection.vue";
import assessmentItem from "./components/assessmentItem.vue";
import itemBody from "./components/itemBody.vue";

import audioInteraction from "./components/audioInteraction.vue";
import choiceInteraction from "./components/choiceInteraction.vue";
import matchInteraction from "./components/matchInteraction.vue";
import hotspotInteraction from "./components/hotspotInteraction.vue";
import textEntryInteraction from "./components/textEntryInteraction.vue";
import extendedTextInteraction from "./components/extendedTextInteraction.vue";
import inlineChoiceInteraction from "./components/inlineChoiceInteraction.vue";
import drawingInteraction from "./components/drawingInteraction.vue";
import uploadInteraction from "./components/uploadInteraction.vue";

import simpleChoice from "./components/simpleChoice.vue";
import inlineChoice from "./components/inlineChoice.vue";
import hotspotChoice from "./components/hotspotChoice.vue";
import simpleAssociableChoice from "./components/simpleAssociableChoice.vue";

import simpleMatchSet from "./components/simpleMatchSet.vue";

import prompt from "./components/prompt.vue";
import ordering from "./components/ordering.vue";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.component("crmx", crmx);
Vue.component("revisionsDrawer", revisionsDrawer);
Vue.component("programming-interaction", programmingInteraction);

// QTI v2
Vue.component("assessmentTest", assessmentTest);
Vue.component("testPart", testPart);
Vue.component("fullscreenTestPart", fullscreenTestPart);
Vue.component("assessmentSection", assessmentSection);
Vue.component("assessmentItem", assessmentItem);
Vue.component("itemBody", itemBody);

Vue.component("choiceInteraction", choiceInteraction);
Vue.component("matchInteraction", matchInteraction);
Vue.component("hotspotInteraction", hotspotInteraction);
Vue.component("textEntryInteraction", textEntryInteraction);
Vue.component("extendedTextInteraction", extendedTextInteraction);
Vue.component("inlineChoiceInteraction", inlineChoiceInteraction);
Vue.component("drawingInteraction", drawingInteraction);
Vue.component("uploadInteraction", uploadInteraction);

Vue.component("simpleChoice", simpleChoice);
Vue.component("inlineChoice", inlineChoice);
Vue.component("hotspotChoice", hotspotChoice);
Vue.component("simpleAssociableChoice", simpleAssociableChoice);

Vue.component("simpleMatchSet", simpleMatchSet);

Vue.component("prompt", prompt);
Vue.component("ordering", ordering);

// QTI v3
Vue.component("qti-custom-interaction", customInteraction);
Vue.component("audio-interaction", audioInteraction);

Vue.component("qti-assessment-test", assessmentTest);
Vue.component("qti-test-part", testPart);
Vue.component("qti-fullscreen-test-part", fullscreenTestPart);
Vue.component("qti-assessment-section", assessmentSection);
Vue.component("qti-assessment-item", assessmentItem);
Vue.component("qti-item-body", itemBody);

Vue.component("qti-choice-interaction", choiceInteraction);
Vue.component("qti-match-interaction", matchInteraction);
Vue.component("qti-hotspot-interaction", hotspotInteraction);
Vue.component("qti-text-entry-interaction", textEntryInteraction);
Vue.component("qti-extended-text-interaction", extendedTextInteraction);
Vue.component("qti-inline-choice-interaction", inlineChoiceInteraction);
Vue.component("qti-drawing-interaction", drawingInteraction);
Vue.component("qti-upload-interaction", uploadInteraction);

Vue.component("qti-simple-choice", simpleChoice);
Vue.component("qti-inline-choice", inlineChoice);
Vue.component("qti-hotspot-choice", hotspotChoice);
Vue.component("qti-simple-associable-choice", simpleAssociableChoice);

Vue.component("qti-simple-match-set", simpleMatchSet);

Vue.component("qti-prompt", prompt);
Vue.component("qti-ordering", ordering);

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
