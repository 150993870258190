import Konva from "konva";

export class Stage {
  modes = {
    BRUSH: "brush",
    ERASER: "eraser",
    SELECT: "select"
  };

  constructor({
    container,
    width,
    height,
    loadImage,
    strokeColor,
    strokeWidth,
    data
  }) {
    this.eventListeners = {};

    this.nodesRedo = [];
    this.nodesDeleted = [];

    this.container = container;
    this.width = width;
    this.height = height;

    this.loadImage = loadImage;

    this.strokeColor = strokeColor;
    this.strokeWidth = strokeWidth;

    this.selectionCoords = { x1: 0, y1: 0, x2: 0, y2: 0 };

    this.painting = false;
    this._mode = null;

    if (data) {
      this.fromJSON(data);
    } else {
      this.createDefaultStage();
    }
  }

  emitEvent(type, event) {
    const listeners = this.eventListeners[type] || [];
    listeners.forEach(cb => cb(event));
  }

  addEventListener(type, callback) {
    if (this.eventListeners[type] === undefined) {
      this.eventListeners[type] = [];
    }

    this.eventListeners[type].push(callback);
  }

  createDefaultStage() {
    this.imageElement = new Image(this.loadImage.width, this.loadImage.height);
    this.imageElement.crossOrigin = "Anonymous";
    this.imageElement.src = this.loadImage.src;

    this.imageElement.onload = () => {
      this.stage = new Konva.Stage({
        name: "stage",
        container: this.container,
        width: this.width,
        height: this.height,
        pixelRatio: 1
      });

      this.layer = new Konva.Layer({
        name: "drawing"
      });

      this.stage.add(this.layer);

      this.background = new Konva.Rect({
        name: "background",
        x: 0,
        y: 0,
        width: this.width,
        height: this.height,
        fill: "#ffffff"
      });
      this.layer.add(this.background);

      this.image = new Konva.Image({
        x: 0,
        y: 0,
        image: this.imageElement,
        width: this.imageElement.width,
        height: this.imageElement.height
      });

      this.image.setAttr("imageSrc", this.loadImage.src);
      this.image.setAttr("imageWidth", this.loadImage.width);
      this.image.setAttr("imageHeight", this.loadImage.height);

      this.layer.add(this.image);

      this.tr = new Konva.Transformer({
        name: "transformer",
        rotateEnabled: false
      });

      this.layer.add(this.tr);

      this.selectionRect = new Konva.Rect({
        name: "selection",
        stroke: "#1D83FF",
        strokeWidth: 0.8,
        fill: "rgba(29, 131, 255, .2)",
        visible: false
      });

      this.layer.add(this.selectionRect);
      this.layer.draw();

      this.registerStageEventListeners();
    };
  }

  fromJSON(data) {
    if (this.stage) this.stage.destroy();
    this.stage = Konva.Node.create(data, this.container);
    this.stage.find("Image").forEach(imageNode => {
      this.image = imageNode;
      this.imageElement = new Image(
        imageNode.getAttr("imageWidth"),
        imageNode.getAttr("imageHeight")
      );
      this.imageElement.crossOrigin = "Anonymous";
      this.imageElement.src = imageNode.getAttr("imageSrc");
      this.imageElement.onload = () => {
        imageNode.image(this.imageElement);
        imageNode.getLayer().draw();
      };
    });

    const layers = [...this.stage.getLayers()];
    this.layer = layers.find(l => l.attrs.name === "drawing");

    const nodes = [...this.layer.getChildren()];
    this.background = nodes.find(l => l.attrs.name === "background");
    this.tr = nodes.find(l => l.attrs.name === "transformer");
    this.selectionRect = nodes.find(l => l.attrs.name === "selection");

    this.selectionRect.visible(false);
    this.layer.draw();
    this.registerStageEventListeners();
  }

  registerStageEventListeners() {
    this.stage.on("mousedown touchstart", event => {
      switch (this.mode) {
        case this.modes.BRUSH:
        case this.modes.ERASER:
          this.onPaintStart(event);
          break;

        case this.modes.SELECT:
          this.onSelectionStart(event);
          break;

        default:
          break;
      }
    });

    this.stage.on("mouseup touchend", event => {
      switch (this.mode) {
        case this.modes.BRUSH:
        case this.modes.ERASER:
          this.onPaintStop(event);
          break;

        case this.modes.SELECT:
          this.onSelectionStop(event);
          break;

        default:
          break;
      }
    });

    this.stage.on("mousemove touchmove", event => {
      switch (this.mode) {
        case this.modes.BRUSH:
        case this.modes.ERASER:
          this.onPaintMove(event);
          break;

        case this.modes.SELECT:
          this.onSelectionMove(event);
          break;

        default:
          break;
      }
    });

    this.stage.on("click tap", event => {
      switch (this.mode) {
        case this.modes.BRUSH:
        case this.modes.ERASER:
          break;

        case this.modes.SELECT:
          this.onSelectionClick(event);
          break;

        default:
          break;
      }
    });

    this.tr.on("transformend", () => {
      this.emitEvent("change", this);
    });
  }

  toJSON() {
    return this.stage.toJSON();
  }

  toDataURL() {
    return this.stage.toDataURL({
      mimeType: "image/jpeg",
      quality: 0.8
    });
  }

  set mode(mode) {
    // deselect all nodes
    this.deselect();
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  canUndo() {
    return (
      this.layer &&
      (this.layer.find(".stroke").length > 0 || this.nodesDeleted.length > 0)
    );
  }

  canRedo() {
    return this.nodesRedo.length > 0;
  }

  canDelete() {
    return this.tr && this.tr.nodes().length > 0;
  }

  undo() {
    this.deselect();

    if (this.nodesDeleted.length > 0) {
      this.nodesDeleted.forEach(node => this.layer.add(node));
      this.nodesDeleted = [];
      this.layer.draw();
      return;
    }

    const strokes = this.layer.find(".stroke");
    if (strokes.length === 0) return;
    const lastStroke = strokes[strokes.length - 1];
    this.nodesRedo.push(lastStroke);
    lastStroke.remove();
    this.layer.draw();
  }

  redo() {
    this.deselect();

    if (this.nodesRedo.length === 0) return;
    const node = this.nodesRedo.pop();
    this.layer.add(node);

    this.layer.draw();
  }

  delete() {
    const nodes = this.tr.nodes();
    this.tr.nodes([]);
    nodes.forEach(node => node.remove());
    this.nodesDeleted = nodes;
    this.layer.draw();
  }

  deleteAll() {
    const nodes = this.layer.find(".stroke");
    nodes.forEach(node => node.remove());
    this.nodesDeleted = nodes;
    this.layer.draw();
  }

  deselect() {
    if (this.tr) {
      this.tr.nodes([]);
      this.layer.draw();
    }
  }

  onPaintStart() {
    const pos = this.stage.getPointerPosition();

    const line = new Konva.Line({
      name: "stroke",
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      globalCompositeOperation:
        this._mode === "brush" ? "source-over" : "destination-out",
      points: [pos.x, pos.y]
    });

    this.painting = line;

    this.layer.add(line);

    this.nodesDeleted = [];
    this.nodesRedo = [];
  }

  onPaintStop() {
    if (this.painting) {
      this.emitEvent("change", this);
    }
    this.painting = null;
  }

  onPaintMove(event) {
    if (!this.painting) return;

    // prevent scroll on touch based devices
    event.evt.preventDefault();

    const pos = this.stage.getPointerPosition();
    var newPoints = this.painting.points().concat([pos.x, pos.y]);
    this.painting.points(newPoints);
    this.layer.batchDraw();
  }

  onSelectionStart(event) {
    if (
      event.target !== this.stage &&
      event.target !== this.image &&
      event.target !== this.background
    ) {
      return;
    }

    const { x, y } = this.stage.getPointerPosition();

    this.selectionCoords = {
      x1: x,
      x2: x,
      y1: y,
      y2: y
    };

    this.selectionRect.visible(true);
    this.selectionRect.setAttrs({
      visible: true,
      x: x,
      y: y,
      width: 0,
      height: 0
    });
    this.layer.draw();
  }

  onSelectionStop() {
    // no nothing if we didn't start selection
    if (!this.selectionRect.visible()) {
      return;
    }

    // update visibility in timeout, so we can check it in click event
    setTimeout(() => {
      this.selectionRect.visible(false);
      this.layer.draw();
    });

    const box = this.selectionRect.getClientRect();
    const selected = this.layer
      .find(".stroke")
      .filter(shape => Konva.Util.haveIntersection(box, shape.getClientRect()));

    // all currently selected nodes should no longer be draggable
    this.tr.nodes().forEach(node => {
      node.draggable(false);
    });

    // nodes to be selected should become draggable
    selected.forEach(node => {
      node.draggable(true);
    });

    this.tr.nodes(selected);
    this.layer.draw();

    this.emitEvent("change", this);
  }

  onSelectionMove(event) {
    if (!this.selectionRect.visible()) {
      return;
    }

    // prevent scroll on touch based devices
    event.evt.preventDefault();

    this.selectionCoords.x2 = this.stage.getPointerPosition().x;
    this.selectionCoords.y2 = this.stage.getPointerPosition().y;

    this.selectionRect.setAttrs({
      x: Math.min(this.selectionCoords.x1, this.selectionCoords.x2),
      y: Math.min(this.selectionCoords.y1, this.selectionCoords.y2),
      width: Math.abs(this.selectionCoords.x2 - this.selectionCoords.x1),
      height: Math.abs(this.selectionCoords.y2 - this.selectionCoords.y1)
    });

    this.layer.batchDraw();
  }

  onSelectionClick(event) {
    // clicks should select/deselect shapes
    // if we are selecting with rect, do nothing
    if (this.selectionRect.visible()) {
      return;
    }

    // if click on empty area - remove all selections
    if (
      event.target === this.stage ||
      event.target === this.image ||
      event.target === this.background
    ) {
      this.tr.nodes([]);
      this.layer.draw();
      return;
    }

    if (event.target.attrs.name !== "stroke") {
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed =
      event.evt.shiftKey || event.evt.ctrlKey || event.evt.metaKey;
    const isSelected = this.tr.nodes().includes(event.target);

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      event.target.draggable(true);
      this.tr.nodes([event.target]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      event.target.draggable(false);
      this.tr.nodes(this.tr.nodes().filter(node => node !== event.target));
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      event.target.draggable(true);
      this.tr.nodes([...this.tr.nodes(), event.target]);
    }

    this.layer.draw();
  }
}
