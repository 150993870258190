<template>
  <div
    data-tag="choiceInteraction"
    :data-response-identifier="responseIdentifier"
    class="pt-1"
  >
    <slot v-if="maxChoices !== 1"></slot>
    <template v-else>
      <v-radio-group
        ref="radio-group"
        v-model="radioValue"
        :rules="responseValueRules"
        hide-details
        class="mt-0 pt-1"
      >
        <slot></slot>
      </v-radio-group>
    </template>
  </div>
</template>

<script>
import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

import { shuffleArray } from "@/helpers";

export default {
  name: "choiceInteraction",

  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],
  data() {
    return {
      radioValue: null,
      timeoutId: undefined,

      shuffle: undefined,
      maxChoices: undefined,
      minchoices: undefined
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    }
  },

  methods: {
    clearSelection() {
      if (this.choiceType() === "radio") {
        this.radioValue = null;
      } else {
        this.commitValue([]);
      }
    },
    parseAttritubes() {
      this.shuffle = this.$attrs["shuffle"] === "true";

      if (this.$attrs["max-choices"] !== undefined) {
        this.maxChoices = parseInt(this.$attrs["max-choices"], 10);
      } else if (this.$attrs["maxChoices"] !== undefined) {
        this.maxChoices = parseInt(this.$attrs["maxChoices"], 10);
      } else {
        this.maxChoices = 1;
      }

      if (this.$attrs["min-choices"] !== undefined) {
        this.minChoices = parseInt(this.$attrs["min-choices"], 10);
      } else if (this.$attrs["minChoices"] !== undefined) {
        this.minChoices = parseInt(this.$attrs["minChoices"], 10);
      } else {
        this.minChoices = 0;
      }
    },

    shuffleOptions() {
      const radio = this.maxChoices === 1;

      // shuffle radio group children if it is a radio choice question
      const radioGroup = this.$refs["radio-group"];
      if (
        radio &&
        (!radioGroup ||
          !radioGroup.$children ||
          radioGroup.$children.length === 0)
      ) {
        // wait until next tick for radio-group to be mounted.
        this.$nextTick(() => {
          this.shuffleOptions();
        });
        return;
      }

      let parent = this;
      let parentElement = this.$el;
      if (radio) {
        parent = radioGroup;
        parentElement = radioGroup.$children[0].$el.parentElement;
      }

      const childElements = [];
      const fixedElements = [];
      const otherElements = [];

      parent.$children.forEach((child, childIndex) => {
        if (childIndex === 0 && child.$options.name !== "simpleChoice") {
          // do not shuffle prompt
          return;
        }

        if (child.other) {
          otherElements.push(child.$el);
        } else if (child.fixed) {
          fixedElements.push([childIndex, child.$el]);
        } else {
          childElements.push(child.$el);
        }
      });

      shuffleArray(childElements);

      fixedElements.forEach(([index, element]) => {
        // insert fixed option at index
        childElements.splice(index, 0, element);
      });

      childElements.forEach(el => parentElement.appendChild(el));
      otherElements.forEach(el => parentElement.appendChild(el));
    },

    choiceType() {
      if (this.maxChoices === 1) {
        return "radio";
      } else {
        return "checkbox";
      }
    },

    checkboxValueChange({ identifier, checked }) {
      let values = [];
      if (Array.isArray(this.storedValue)) {
        values = this.storedValue;
      }

      if (checked && !values.includes(identifier)) {
        this.commitValue([...values, identifier]);
      } else if (!checked && values.includes(identifier)) {
        this.commitValue(values.filter(id => id !== identifier));
      }
    },

    radioValueChange() {
      this.commitValue(this.radioValue);
    }
  },

  watch: {
    storedValue(value) {
      if (this.choiceType() === "radio") {
        this.radioValue = value;
      }
    },

    radioValue() {
      this.radioValueChange();
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      if (revision.form[this.responseIdentifier]) {
        this.commitValue(revision.form[this.responseIdentifier]);
        this.$store.commit("setOtherValue", {
          responseIdentifier: this.responseIdentifier,
          value: revision.other[this.responseIdentifier]
        });
      } else this.clearSelection();
    }
  },

  mounted() {
    this.parseAttritubes();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.minChoices > 0);

    this.$on("checkboxChange", this.checkboxValueChange);

    if (this.shuffle) {
      this.shuffleOptions();
    }

    if (this.choiceType() === "radio") {
      this.radioValue = this.storedValue;
      this.addClearSelectionCb(this.clearSelection);
    }
    this.interactionInitialized();
  }
};
</script>
