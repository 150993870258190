<template>
  <div
    data-tag="simpleMatchSet"
    class="d-flex flex-wrap justify-center"
    :class="{
      'mx-2 my-2 align-center': firstChild,
      'drop-zone align-start': !firstChild
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "simpleMatchSet",

  provide() {
    return {
      dragCard: () => this.firstChild
    };
  },

  data: () => ({
    firstChild: false
  }),

  mounted() {
    this.firstChild = this.$el.parentElement.firstChild === this.$el;
  }
};
</script>

<style scoped>
.drop-zone {
  min-height: 50px;
}
</style>
