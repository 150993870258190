<template>
  <v-card class="qti-error-view my-8">
    <v-card-title>
      <h2 class="text-h5">
        <div class="my-4"><v-icon x-large v-text="icons.mdiBugStop" /></div>
        <span v-t="'request_failed_error'"></span>
      </h2>
    </v-card-title>
    <v-card-text>{{ error }}</v-card-text>
  </v-card>
</template>
<script>
import { mdiBugStop } from "@mdi/js";

export default {
  name: "Error",

  data: () => ({
    icons: { mdiBugStop }
  }),

  computed: {
    error() {
      return this.$route.params.error;
    }
  }
};
</script>
<style>
.qti-error-view {
  width: 100%;
  max-width: 726px;
  margin: auto;
  padding: 8px;
}
</style>
