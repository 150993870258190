export default {
  props: {
    responseIdentifier: {
      type: String,
      required: true
    }
  },
  inject: ["assessmentItemId", "interactionInitialized"],

  data() {
    return {
      responseValueRules: [() => this.responseValid()],
      responseValueModified: false
    };
  },
  computed: {
    revision() {
      return this.$store.getters.revision;
    },
    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    }
  },

  methods: {
    responseValid() {
      if (!this.responseValueModified) return true;
      return this.$store.getters.responseValid(this.responseIdentifier);
    },

    commitValue(value) {
      if (value === this.storedValue) return;
      if (!this.responseValueModified) this.responseValueModified = true;

      this.$store.commit("setResponseIdentifierValue", {
        responseIdentifier: this.responseIdentifier,
        value: value
      });
    },

    setResponseIdentifierRequired(required) {
      if (!required) return;

      this.$store.commit("setResponseIdentifierRequired", {
        responseIdentifier: this.responseIdentifier
      });
    },

    registerResponseIdentifier() {
      this.$store.commit("registerResponseIdentifier", {
        assessmentItemId: this.assessmentItemId,
        responseIdentifier: this.responseIdentifier
      });
    }
  }
};
