<template>
  <div
    :class="{
      textEntryInteraction: true,
      'inline-block': inline
    }"
    data-tag="textEntryInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <span v-if="autoResize" ref="width-calc" class="width-calc"></span>
    <v-text-field
      ref="input"
      :class="{
        'input-auto-resize': autoResize
      }"
      v-model="inputValue"
      hide-details="auto"
      :filled="filled"
      :dense="dense"
      :single-line="dense"
      :size="size"
      spellcheck="false"
      autocomplete="off"
      :rules="inputRules"
      :background-color="backgroundColor"
      :disabled="readOnly"
    >
      <template v-slot:append>
        <speech-synthesis-btn :text="inputValue" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

export default {
  name: "textEntryInteraction",

  mixins: [responseIdentifierMixin],

  components: {
    speechSynthesisBtn
  },
  data() {
    return {
      inputValue: "",
      size: null,
      watchHidden: true,

      required: undefined,
      inline: undefined,
      filled: undefined,
      dense: undefined,
      patternMask: null,
      patternmaskMessage: null,
      inputRules: undefined,
      autoResize: false,
      backgroundColor: undefined
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier) || "";
    },

    hiddenInit() {
      return this.$store.getters.hiddenInit;
    },

    hidden() {
      return this.$store.getters.hidden;
    },

    style() {
      return this.$attrs["data-crmx-style"];
    }
  },

  methods: {
    parseAttributes() {
      this.required = this.$attrs["data-required"] === "true";
      this.inline = this.$attrs["data-input-inline"] !== "false";

      this.filled = this.$attrs["data-input-filled"] === "true";
      this.dense = this.$attrs["data-input-dense"] !== "false";

      this.autoResize = this.$attrs["data-input-autoresize"] === "true";
      this.backgroundColor = this.$attrs["data-background-color"];

      if (this.$attrs["expected-length"]) {
        this.size = this.$attrs["expected-length"];
      }
      if (this.$attrs.expectedLength) {
        this.size = this.$attrs.expectedLength;
      }
      if (this.$attrs["data-pattern-mask"]) {
        this.patternMask = new RegExp(this.$attrs["data-pattern-mask"]);
        this.patternmaskMessage = this.$attrs["data-patternmask-message"];
        this.inputRules = [
          ...this.responseValueRules,
          v => {
            if (!this.responseValueModified) return true;
            if (!v) return true;
            if (this.patternMask.test(v)) return true;
            return this.patternmaskMessage || false;
          }
        ];
      } else {
        this.inputRules = this.responseValueRules;
      }
    },
    resizeInput() {
      if (this.autoResize) {
        this.$refs["width-calc"].innerText = this.inputValue;
        this.$el.style.setProperty(
          "--input-width",
          `${Math.max(this.$refs["width-calc"].offsetWidth + 4, 24)}px`
        );
      }
    }
  },

  watch: {
    inputValue(value) {
      this.commitValue(value);
      this.$nextTick(this.resizeInput);
    },

    hiddenInit(v) {
      if (v) {
        this.$nextTick(this.resizeInput);
        this.watchHidden = false;
      }
    },
    hidden(v) {
      if (!v && this.watchHidden) {
        setTimeout(() => {
          this.$nextTick(this.resizeInput);
        }, 0);
      }
      this.watchHidden = false;
    },

    $route(to) {
      if (to.query.itemId === this.assessmentItemId) {
        this.$nextTick(this.resizeInput);
      }
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      if (revision.form[this.responseIdentifier]) {
        this.inputValue = revision.form[this.responseIdentifier];
      } else this.inputValue = null;
    }
  },
  mounted() {
    this.parseAttributes();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.required);
    this.inputValue = this.storedValue;
    if (this.autoResize) {
      this.$el.style.setProperty("--input-width", `24px`);
    }
    this.interactionInitialized();
  }
};
</script>
<style>
span.width-calc {
  position: absolute;
  visibility: hidden;
  white-space: pre;
}
.textEntryInteraction.inline-block {
  display: inline-block;
}

.textEntryInteraction.inline-block .v-text-field {
  display: inline-block;
  margin: 2px 0px;
  vertical-align: bottom;
}

.textEntryInteraction.inline-block .v-text-field input {
  margin: 0px;
  padding: 0px 0px 0px 2px;
  font-size: 0.875em;
}

.textEntryInteraction.inline-block .v-text-field.input-auto-resize input {
  width: var(--input-width);
}
</style>
