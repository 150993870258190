<template>
  <div data-tag="testPart" :identifier="identifier" v-show="showComponent">
    <slot></slot>
  </div>
</template>

<script>
import displayMixin from "@/mixins/displayMixin";

export default {
  name: "fullscreenTestPart",

  mixins: [displayMixin],

  props: {
    identifier: String,
    title: String,
    assessmentItemIdentifier: String
  },
  provide() {
    return {
      assessmentItemId: this.assessmentItemIdentifier
    };
  },

  methods: {
    onNavigation(to) {
      if (to.query.partId === this.identifier) {
        const query = { ...to.query };

        const display = this.$el.querySelector(
          'div[data-tag="crmx"][data-name="nonlinear"][data-value="page"]'
        )
          ? "page"
          : undefined;

        let push = false;
        if (query.display !== display) {
          query.display = display;
          push = true;
        }

        if (push) {
          this.$router.replace({ query }).catch(() => {});
        }
      }
    }
  },

  watch: {
    $route(to, from) {
      if (from && to.query.partId === from.query.partId) return;
      this.onNavigation(to);
    }
  },

  mounted() {
    this.onNavigation(this.$route);
  }
};
</script>
