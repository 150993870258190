<template>
  <div>
    <v-list :disabled="revisionLoading" ref="list">
      <v-list-item
        v-for="item in generations"
        :key="item.timestamp"
        link
        @click="revisionLoad(item.generation)"
        three-line
        :input-value="generation === item.generation"
        :disabled="revisionLoading"
      >
        <v-list-item-content>
          <v-list-item-title>
            <span v-text="$d(new Date(item.timestamp), 'dateTime')" />
          </v-list-item-title>
          <v-list-item-subtitle class="py-2" v-if="moduleType === 'qti_editor'">
            <span
              v-t="{
                path: 'detailed_word_count',
                args: {
                  numChars: item.metadata.char_count,
                  numWords: item.metadata.word_count
                }
              }"
            />
          </v-list-item-subtitle>
          <v-list-item-subtitle
            class="py-2"
            v-else-if="moduleType === 'qti_form'"
          >
            <span
              v-t="{
                path: 'stats_questions_answered',
                args: {
                  count: item.metadata.answered,
                  total: item.metadata.questions
                }
              }"
            />
          </v-list-item-subtitle>
          <v-btn
            v-if="generation === item.generation"
            @click.stop="activate(item.generation)"
            small
            depressed
            color="primary"
            class="d-flex"
          >
            <span v-t="'use_revision'" />
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { logger } from "@/helpers";
import messaging from "@/plugins/messaging";
export default {
  name: "revisions-drawer",

  props: {
    value: Boolean
  },

  data() {
    return {
      revisionLoading: false,
      latest: null,
      generation: null,
      generations: []
    };
  },

  computed: {
    revision() {
      return this.$store.getters.revision;
    },
    moduleType() {
      return this.$store.getters.moduleType;
    }
  },

  methods: {
    close() {
      this.$store.commit("setRevisionDialog", false);
    },
    activate() {
      this.close();
      if (this.latest !== this.generation) this.$emit("activated");
    },
    revisionLoad(generation) {
      if (generation === this.generation) return;
      this.revisionLoading = true;

      messaging
        .sendMessage("CONTENT_GET", {
          generation: generation
        })
        .then(result => {
          this.$store.commit("setRevision", result.content);
          this.errorMessage = null;
          this.generation = generation;
        })
        .catch(failure => {
          logger.warn(failure.message);
        })
        .finally(() => {
          this.revisionLoading = false;
        });
    },

    updateVersionHistory() {
      this.revisionLoading = true;
      this.generation = null;
      this.latest = null;
      messaging
        .sendMessage("CONTENT_GENERATIONS", {})
        .then(result => {
          if (result) {
            this.generations = result.generations.slice().reverse();
            this.errorMessage = null;
            if (this.generations.length > 0) {
              this.generation = this.generations[0].generation;
              this.latest = this.generations[0].generation;
              this.$nextTick(() => {
                this.$refs["list"].$el.firstChild.focus();
              });
            } else this.close();
          }
        })
        .catch(failure => {
          logger.warn(failure.message);
          this.close();
        })
        .finally(() => {
          this.revisionLoading = false;
        });
    }
  },

  watch: {
    value(value) {
      if (value) {
        this.updateVersionHistory();
      }
    }
  }
};
</script>
