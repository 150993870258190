<template>
  <div class="simpleChoice" data-tag="simpleChoice">
    <v-checkbox
      :input-value="inputValue"
      @change="checkboxChanged"
      v-if="choiceType === 'checkbox'"
      hide-details
      class="mt-0 mb-1"
      :error="checkboxError"
      :disabled="readOnly"
    >
      <template v-if="!other" v-slot:label>
        <slot></slot>
        <speech-synthesis-btn :text="choiceLabel" />
      </template>
      <template v-else v-slot:label>
        <v-text-field
          v-model="otherInputValue"
          @click.stop=""
          class="mt-0 pt-0"
          hide-details
          single-line
          :error="otherInputError"
          :disabled="readOnly"
        />
      </template>
    </v-checkbox>
    <v-radio
      :value="identifier"
      v-else-if="choiceType === 'radio'"
      class="mb-2"
      :disabled="readOnly"
    >
      <template v-if="!other" v-slot:label>
        <slot></slot>
        <speech-synthesis-btn :text="choiceLabel" />
      </template>
      <template v-else v-slot:label>
        <v-text-field
          v-model="otherInputValue"
          @click.stop=""
          class="mt-0 pt-0"
          hide-details
          single-line
          :error="otherInputError"
        />
      </template>
    </v-radio>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

export default {
  name: "simpleChoice",

  props: {
    identifier: {
      type: String
    }
  },
  components: {
    speechSynthesisBtn
  },

  data() {
    return {
      parent: undefined,
      choiceType: undefined,
      responseIdentifier: undefined,

      inputValue: false,

      fixed: undefined,
      other: undefined,
      otherInputValue: "",

      choiceLabel: ""
    };
  },

  computed: {
    revision() {
      return this.$store.getters.revision;
    },
    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    },
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    },

    storedOtherValue() {
      return this.$store.getters.otherValue(this.responseIdentifier) || "";
    },

    checkboxError() {
      const required = this.$store.getters.responseRequired(
        this.responseIdentifier
      );
      if (!this.storedValue || !required) {
        return false;
      }

      return this.storedValue.length < 1;
    },

    otherInputError() {
      if (this.otherInputValue) return false;

      const choiceSelected = Array.isArray(this.storedValue)
        ? this.storedValue.includes(this.identifier)
        : this.storedValue === this.identifier;

      return choiceSelected;
    }
  },

  methods: {
    parseAttributes() {
      this.fixed = this.$attrs.fixed === "true";
      this.other = this.$attrs["data-other"] === "true";
    },

    getParentInteraction(element) {
      if (["choiceInteraction"].includes(element.$options.name)) {
        return element;
      } else if (element.$parent) {
        return this.getParentInteraction(element.$parent);
      }
    },

    setInputValue() {
      if (this.choiceType === "checkbox" && Array.isArray(this.storedValue)) {
        this.inputValue = this.storedValue.includes(this.identifier);
      }
    },

    checkboxChanged(checked) {
      this.parent.$emit("checkboxChange", {
        identifier: this.identifier,
        checked
      });
    },

    commitOtherValue(value) {
      if (value === this.storedOtherValue) return;

      this.$store.commit("setOtherValue", {
        responseIdentifier: this.responseIdentifier,
        value: this.otherInputValue
      });
    }
  },

  watch: {
    otherInputValue(value) {
      this.commitOtherValue(value);
    },
    revision(revision) {
      if (!this.responseIdentifier || !revision || !revision.form) return;
      const value = revision.form[this.responseIdentifier];
      if (this.choiceType === "checkbox") {
        if (value && Array.isArray(value)) {
          this.inputValue = value.includes(this.identifier);
        } else this.inputValue = null;
      }
      if (this.other) {
        this.otherInputValue = revision.other[this.responseIdentifier];
      }
    }
  },

  created() {
    this.parseAttributes();

    this.parent = this.getParentInteraction(this.$parent);
    this.responseIdentifier = this.parent.responseIdentifier;

    this.choiceType = this.parent.choiceType();
    this.setInputValue();
    this.otherInputValue = this.storedOtherValue;

    // fetch the label from the default slot
    const slot = this.$slots.default[0];
    if (slot.text) this.choiceLabel = slot.text.trim();
    else {
      const span = slot.children[1].children[0];
      this.choiceLabel = span.text ? span.text.trim() : "";
    }
  }
};
</script>
