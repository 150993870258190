<template>
  <div
    class="extendedTextInteraction"
    data-tag="extendedTextInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <slot></slot>

    <froala-space
      v-if="wysiwyg"
      ref="editor"
      v-model="inputValue"
      :fullscreen="fullscreen"
      :max-words="maxWords"
    ></froala-space>

    <v-textarea
      v-else
      class="mt-3"
      v-model="inputValue"
      hide-details
      filled
      spellcheck="false"
      autocomplete="off"
      :rules="responseValueRules"
      :readonly="readOnly"
    >
      <template v-slot:append>
        <speech-synthesis-btn :text="inputValue" />
      </template>
    </v-textarea>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";
const froalaSpace = () =>
  import(/* webpackChunkName: "froala" */ "@/components/froalaSpace.vue");

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

export default {
  name: "extendedTextInteraction",

  mixins: [responseIdentifierMixin],

  components: {
    froalaSpace,
    speechSynthesisBtn
  },

  data() {
    return {
      maxWords: null,

      inputValue: "",

      fullscreen: false,
      wysiwyg: false,

      minStrings: undefined
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier) || "";
    },

    moduleType() {
      return this.$store.getters.moduleType;
    }
  },

  methods: {
    parseAttributes() {
      if (this.$attrs["data-max-words"] !== undefined) {
        this.maxWords = parseInt(this.$attrs["data-max-words"], 10);
      }
      this.fullscreen = this.$attrs["data-fullscreen"] === "true";
      this.wysiwyg = this.$attrs["data-wysiwyg"] === "true";
      if (this.$attrs["min-strings"] !== undefined) {
        this.minStrings = parseInt(this.$attrs["min-strings"], 10);
      } else if (this.$attrs["minStrings"] !== undefined) {
        this.minStrings = parseInt(this.$attrs["minStrings"], 10);
      } else {
        this.minStrings = 0;
      }
    }
  },

  watch: {
    inputValue(value) {
      this.commitValue(value);
    },
    revision(revision) {
      if (revision == null) return;
      if (this.moduleType === "qti_editor") {
        this.inputValue = revision;
      } else {
        this.inputValue = revision.form[this.responseIdentifier];
      }
    }
  },

  mounted() {
    this.parseAttributes();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.minStrings > 0);

    this.inputValue = this.storedValue;
    if (!this.wysiwyg) {
      this.interactionInitialized();
    }
  }
};
</script>
