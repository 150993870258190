export let debugMode = false;

if (
  window.location.hostname === "localhost" ||
  /debug=true/i.test(window.location.search)
) {
  debugMode = true;
}

export const allowedOrigins = process.env.VUE_APP_ALLOWED_ORIGINS.split(" ");
