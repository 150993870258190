<template>
  <option data-tag="inlineChoice" :value="identifier" :fixed="fixed">
    <slot></slot>
  </option>
</template>

<script>
export default {
  name: "inlineChoice",
  props: {
    identifier: String,
    fixed: String
  }
};
</script>
