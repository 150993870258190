<template>
  <div
    style="margin: 0 auto; overflow-x: auto;"
    class="py-1"
    data-tag="hotspotInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <div class="canvas-wrapper" ref="hotspot" :data-pointer="showPointer"></div>

    <div style="display: none;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";
import Konva from "konva";

export default {
  name: "hotspotInteraction",

  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],

  data() {
    return {
      minChoices: 0,

      stage: null,

      imageSrc: null,
      imageWidth: null,
      imageHeight: null,

      showPointer: false,
      selectedId: undefined,

      choices: {}
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier) || [];
    }
  },

  methods: {
    clearSelection() {
      if (!this.storedValue) return;
      const selected = this.choices[this.storedValue];
      if (selected) {
        selected.opacity(0);
        selected.stroke("#000000");
        selected.getLayer().draw();
      }
      this.commitValue(null);
      this.selectedId = null;
    },
    parseOptions() {
      if (this.$attrs["min-choices"] !== undefined) {
        this.minChoices = parseInt(this.$attrs["min-choices"], 10);
      } else if (this.$attrs["minChoices"] !== undefined) {
        this.minChoices = parseInt(this.$attrs["minChoices"], 10);
      } else {
        this.minChoices = 0;
      }
    },

    init() {
      const ctx = this;
      const container = this.$refs.hotspot;
      this.stage = new Konva.Stage({
        container: container,
        width: this.imageWidth,
        height: this.imageHeight
      });

      this.stage.preventDefault(false);
      const layer = new Konva.Layer();

      this.stage.add(layer);

      let imageObj = new Image(this.imageWidth, this.imageHeight);
      imageObj.crossOrigin = "Anonymous";
      imageObj.src = this.imageSrc;
      imageObj.onload = function() {
        const backgroundImage = new Konva.Image({
          x: 0,
          y: 0,
          width: this.imageWidth,
          height: this.imageHeight,
          image: imageObj
        });
        layer.add(backgroundImage);
        backgroundImage.preventDefault(false);
        backgroundImage.moveToBottom();
        layer.draw();

        ctx.interactionInitialized();
      };

      const elements = this.$el.querySelectorAll(
        "div[data-tag='hotspotChoice']"
      );
      for (let i = 0; i < elements.length; i++) {
        const shape = elements[i].getAttribute("shape");
        const identifier = elements[i].getAttribute("identifier");
        const coords = elements[i].getAttribute("coords").split(",");
        let el;
        const config = {
          stroke: "#000000",
          strokeWidth: 1,
          opacity: 0,
          x: parseInt(coords[0]),
          y: parseInt(coords[1])
        };
        if (shape === "ellipse") {
          el = new Konva.Ellipse(config);
          el.radiusX(parseInt(coords[2]));
          el.radiusY(parseInt(coords[3]));
        } else if (shape === "circle") {
          el = new Konva.Circle(config);
          el.radius(parseInt(coords[2]));
        } else if (shape === "poly") {
          el = new Konva.Line(config);
          el.position({ x: 0, y: 0 });
          el.closed(true);
          el.points(coords);
        } else {
          el = new Konva.Rect(config);

          el.size({
            width: coords[2] - coords[0],
            height: coords[3] - coords[1]
          });
        }
        el.on("mouseenter", () => {
          this.onMouseEnter(identifier);
        });
        el.on("mouseleave", () => {
          this.onMouseLeave(identifier);
        });
        el.on("click tap", () => {
          this.onMouseClick(identifier);
        });
        layer.add(el);
        this.choices[identifier] = el;
      }

      if (this.storedValue) {
        this.onMouseClick(this.storedValue);
      }
    },
    onMouseEnter(id) {
      this.choices[id].opacity(1);
      if (id !== this.selectedId) {
        this.showPointer = true;
      }
      this.choices[id].getLayer().draw();
    },
    onMouseLeave(id) {
      if (this.selectedId !== id) this.choices[id].opacity(0);
      this.showPointer = false;
      this.choices[id].getLayer().draw();
    },
    onMouseClick(id) {
      if (this.selectedId !== id && !!this.choices[id]) {
        this.choices[id].stroke("#ff0000");
        this.choices[id].opacity(1);
        if (this.selectedId && this.choices[this.selectedId]) {
          this.choices[this.selectedId].opacity(0);
          this.choices[this.selectedId].stroke("#000000");
        }
        this.selectedId = id;
        this.showPointer = false;
        this.choices[id].getLayer().draw();
        this.commitValue(id);
      }
    }
  },

  watch: {
    revision(revision) {
      if (!revision || !revision.form) return;
      if (revision.form[this.responseIdentifier]) {
        const id = revision.form[this.responseIdentifier];
        this.onMouseClick(id);
      } else this.clearSelection();
    },
    readOnly(value) {
      this.stage.listening(!value);
    }
  },

  mounted() {
    this.parseOptions();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.minChoices > 0);

    const object = this.$el.querySelector("object");

    this.imageSrc = object.data;
    this.imageWidth = parseInt(object.width);
    this.imageHeight = parseInt(object.height);

    this.init();
    this.addClearSelectionCb(this.clearSelection);
  }
};
</script>

<style>
.canvas-wrapper .konvajs-content {
  margin: 0 auto;
  display: block;
  outline: 1px solid black;
}

.canvas-wrapper[data-pointer="true"] {
  cursor: pointer;
}
</style>
