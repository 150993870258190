<template>
  <v-card-text data-tag="itemBody" class="itemBody black--text">
    <slot></slot>
    <speech-synthesis-btn
      v-if="containsDescription"
      ref="speechSynthesisBtn"
      :text="descriptionText"
    />
  </v-card-text>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

export default {
  name: "itemBody",

  components: {
    speechSynthesisBtn
  },

  data: () => ({
    containsDescription: false,

    descriptionText: ""
  }),

  mounted() {
    const descriptionEl = this.$el.querySelector("p.crmx-description");
    this.containsDescription = !!descriptionEl;

    if (descriptionEl) {
      // fetch description paragraph text and place button at the end of the
      // description.
      this.$nextTick(() => {
        this.descriptionText = descriptionEl.innerText.trim();
        descriptionEl.appendChild(this.$refs.speechSynthesisBtn.$el);
      });
    }
  }
};
</script>

<style>
div.itemBody > *:first-child {
  margin-top: 0px;
}
</style>
