<template>
  <div
    class="inlineChoiceInteraction"
    data-tag="inlineChoiceInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <v-select
      ref="select"
      hide-details
      :dense="dense"
      :filled="filled"
      v-model="selectedValue"
      :items="items"
      :rules="responseValueRules"
      :disabled="readOnly"
    >
      <template v-if="selectedValue" v-slot:selection="{ item }">
        <span v-text="item.text"></span>
        <speech-synthesis-btn :text="item.text" />
      </template>
      <template v-if="selectedValue" v-slot:item="{ item }">
        <span v-text="item.text"></span>
        <speech-synthesis-btn :text="item.text" />
      </template>
    </v-select>

    <div ref="options" style="display: none;">
      <!-- all inlineChoice elements will be placed in this slot -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

import { shuffleArray } from "@/helpers";

export default {
  name: "inlineChoiceInteraction",

  components: {
    speechSynthesisBtn
  },

  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],

  data() {
    return {
      selectedValue: undefined,
      items: [],

      fixWidthOnce: false,

      inline: undefined,
      dense: undefined,
      filled: undefined,

      shuffle: undefined,
      required: undefined
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    }
  },

  methods: {
    clearSelection() {
      this.selectedValue = null;
    },

    parseAttributes() {
      this.shuffle = this.$attrs["shuffle"] === "true";
      this.required = this.$attrs["required"] === "true";
    },

    parseDataAttributes() {
      this.inline = this.$attrs["data-input-inline"] !== "false";
      this.filled = this.$attrs["data-input-filled"] === "true";
      this.dense = this.$attrs["data-input-dense"] !== "false";
    },

    inlineElement() {
      this.$el.style.display = "inline-block";
      this.$refs.select.$el.style.display = "inline-block";
    },

    lessWidthFix() {
      // v-select has a large min-width by default due to an inner input that
      // has a fixed width of 200px.
      this.$refs.select.$el.querySelector("input[readonly]").style.width =
        "50px";
    },

    parseOptions() {
      // parse options placed in the slot
      const optionElements = this.$el.querySelectorAll("option");
      if (optionElements.length === 0) {
        // wait until options have been mounted.
        this.$nextTick(() => {
          this.parseOptions();
        });
        return;
      }

      const options = [];
      const fixedOptions = [];

      for (let i = 0; i < optionElements.length; i++) {
        const item = {
          text: optionElements[i].innerText,
          value: optionElements[i].value
        };

        if (optionElements[i].getAttribute("fixed") === "true") {
          fixedOptions.push([i, item]);
        } else {
          options.push(item);
        }
      }

      if (this.shuffle) {
        shuffleArray(options);
      }

      fixedOptions.forEach(([index, item]) => {
        // insert fixed option at index
        options.splice(index, 0, item);
      });

      this.items = options;
    },

    onChange() {
      // after an item has been selected make the input even smaller than in our
      // this.lessWidthFix function to make it look nicer.
      if (!this.fixWidthOnce) return;
      this.$refs.select.$el.querySelector("input[readonly]").style.width =
        "5px";
      this.fixWidthOnce = true;
    }
  },

  watch: {
    selectedValue(value) {
      this.commitValue(value);
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      if (revision.form[this.responseIdentifier]) {
        this.selectedValue = revision.form[this.responseIdentifier];
      } else this.clearSelection();
    }
  },

  mounted() {
    this.parseAttributes();
    this.parseDataAttributes();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.required);

    if (this.inline) {
      // element should be inline
      this.inlineElement();
    }

    this.parseOptions();
    this.selectedValue = this.storedValue;
    this.addClearSelectionCb(this.clearSelection);
    this.interactionInitialized();
  }
};
</script>
