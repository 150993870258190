export default {
  computed: {
    showComponent() {
      switch (this.$options.name) {
        case "testPart":
          return this.$route.query.partId === this.identifier;

        case "fullscreenTestPart":
          return this.$route.query.partId === this.identifier;

        case "assessmentSection":
          return this.$route.query.sectionId === this.identifier;

        case "assessmentItem":
          if (this.$route.query.display !== "page") return true;
          return this.$route.query.itemId === this.identifier;

        default:
          throw new Error(`unexpected component: ${this.$options.name}`);
      }
    }
  }
};
