<template>
  <div
    data-tag="drawingInteraction"
    :data-response-identifier="responseIdentifier"
    data-submission-values="true"
  >
    <v-layout justify-center py-2>
      <v-btn
        @click="changeMode('select')"
        :color="interactionMode === 'select' ? 'primary' : 'grey darken-3'"
        :input-value="interactionMode === 'select'"
        class="mx-1"
        icon
        tile
        :ripple="false"
      >
        <v-icon v-text="icons.mdiSelect" />
      </v-btn>

      <v-btn
        @click="changeMode('brush')"
        :color="interactionMode === 'brush' ? 'primary' : 'grey darken-3'"
        :input-value="interactionMode === 'brush'"
        class="mx-1"
        icon
        tile
        :ripple="false"
        :disabled="readOnly"
      >
        <v-icon v-text="icons.mdiBrush" />
      </v-btn>

      <v-btn
        @click="undo"
        color="grey darken-3"
        class="mx-1"
        icon
        tile
        :ripple="false"
        :disabled="!undoEnabled || readOnly"
      >
        <v-icon v-text="icons.mdiUndo" />
      </v-btn>

      <v-btn
        @click="redo"
        color="grey darken-3"
        class="mx-1"
        icon
        tile
        :ripple="false"
        :disabled="!redoEnabled || readOnly"
      >
        <v-icon v-text="icons.mdiRedo" />
      </v-btn>

      <v-btn
        @click="deleteSelected"
        color="grey darken-3"
        class="mx-1"
        icon
        tile
        :ripple="false"
        :disabled="!deleteEnabled || readOnly"
      >
        <v-icon v-text="icons.mdiDelete" />
      </v-btn>
    </v-layout>

    <div
      ref="preview"
      :style="{ height, width }"
      class="drawing-editor"
      :data-mode="interactionMode"
    ></div>

    <div style="display: none;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mdiSelect, mdiBrush, mdiDelete, mdiUndo, mdiRedo } from "@mdi/js";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";

import { Stage } from "../plugins/konva";

export default {
  name: "drawingInteraction",

  mixins: [responseIdentifierMixin],

  data: () => ({
    inputValue: null,

    stage: null,

    redoEnabled: false,
    undoEnabled: false,
    deleteEnabled: false,

    interactionMode: null,
    strokeColor: undefined,
    strokeWidth: 2,

    height: "0px",
    width: "0px",

    icons: {
      mdiSelect,
      mdiBrush,
      mdiDelete,
      mdiUndo,
      mdiRedo
    }
  }),

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier) || null;
    }
  },

  methods: {
    submissionData() {
      return this.stage.toDataURL();
    },

    changeMode(mode) {
      this.stage.deselect();
      this.interactionMode = mode;
    },

    undo() {
      this.stage.undo();

      this.undoEnabled = this.stage.canUndo();
      this.redoEnabled = this.stage.canRedo();
      this.inputValue = this.stage.toJSON();
    },

    redo() {
      this.stage.redo();

      this.undoEnabled = this.stage.canUndo();
      this.redoEnabled = this.stage.canRedo();
      this.inputValue = this.stage.toJSON();
    },

    deleteSelected() {
      this.stage.delete();

      this.undoEnabled = this.stage.canUndo();
      this.redoEnabled = this.stage.canRedo();
      this.deleteEnabled = false;
      this.inputValue = this.stage.toJSON();
    }
  },

  watch: {
    inputValue(value) {
      this.commitValue(value);
    },

    interactionMode(v) {
      this.stage.mode = v;
    },

    strokeColor(v) {
      this.stage.strokeColor = v;
    },

    strokeWidth(v) {
      this.stage.strokeWidth = v;
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      this.interactionMode = "select";
      this.deleteEnabled = false;
      this.inputValue = revision.form[this.responseIdentifier];
      if (this.inputValue) {
        this.stage.fromJSON(this.inputValue);
      } else {
        this.stage.deleteAll();
      }
    },
    readOnly(value) {
      this.stage.stage.listening(!value);
      if (value) this.stage.deselect();
    }
  },

  mounted() {
    this.registerResponseIdentifier();

    const object = this.$el.querySelector("object");

    const stageWidth = parseInt(object.getAttribute("width"), 10);
    const stageHeight = parseInt(object.getAttribute("height"), 10);

    const imageWidth = object.dataset.imageWidth || stageWidth;
    const imageHeight = object.dataset.imageHeight || stageHeight;

    this.strokeColor = object.dataset.strokeColor || "#000000";

    this.width = `${stageWidth}px`;
    this.height = `${stageHeight}px`;

    this.inputValue = this.storedValue;

    const container = this.$el.querySelector(".drawing-editor");
    this.stage = new Stage({
      container,
      width: stageWidth,
      height: stageHeight,
      loadImage: {
        src: object.getAttribute("data"),
        width: imageWidth,
        height: imageHeight
      },
      strokeColor: this.strokeColor,
      strokeWidth: this.strokeWidth,
      data: this.storedValue
    });

    this.stage.addEventListener("change", stage => {
      this.undoEnabled = this.stage.canUndo();
      this.redoEnabled = this.stage.canRedo();
      this.deleteEnabled = this.stage.canDelete();

      this.inputValue = stage.toJSON();
    });

    this.undoEnabled = this.stage.canUndo();
    this.redoEnabled = this.stage.canRedo();

    this.interactionMode = "select";
    this.interactionInitialized();
  }
};
</script>

<style>
.drawing-editor {
  margin: 0 auto;
}

.drawing-editor .konvajs-content {
  cursor: not-allowed;
  outline: 2px solid black;
}

.drawing-editor[data-mode="select"] .konvajs-content {
  cursor: crosshair;
}

.drawing-editor[data-mode="brush"] .konvajs-content {
  cursor: crosshair;
}

.konvajs-content {
  outline: 1px solid #ccc;
  background: white;
}
</style>
