<template>
  <div
    data-tag="hotspotChoice"
    :identifier="identifier"
    :shape="shape"
    :coords="coords"
  ></div>
</template>

<script>
export default {
  name: "hotspotChoice",
  props: {
    identifier: { type: String, required: true },
    shape: { type: String, required: true },
    coords: { type: String, required: true }
  }
};
</script>
