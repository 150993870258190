<template>
  <div
    data-tag="crmx"
    :data-name="dataName"
    :data-value="dataValue"
    style="display: none;"
  ></div>
</template>

<script>
export default {
  name: "crmx",

  props: {
    dataName: {
      type: String,
      required: true
    },

    dataValue: {
      type: String,
      required: true
    }
  }
};
</script>
