var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',{ref:"list",attrs:{"disabled":_vm.revisionLoading}},_vm._l((_vm.generations),function(item){return _c('v-list-item',{key:item.timestamp,attrs:{"link":"","three-line":"","input-value":_vm.generation === item.generation,"disabled":_vm.revisionLoading},on:{"click":function($event){return _vm.revisionLoad(item.generation)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{domProps:{"textContent":_vm._s(_vm.$d(new Date(item.timestamp), 'dateTime'))}})]),(_vm.moduleType === 'qti_editor')?_c('v-list-item-subtitle',{staticClass:"py-2"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:({
              path: 'detailed_word_count',
              args: {
                numChars: item.metadata.char_count,
                numWords: item.metadata.word_count
              }
            }),expression:"{\n              path: 'detailed_word_count',\n              args: {\n                numChars: item.metadata.char_count,\n                numWords: item.metadata.word_count\n              }\n            }"}]})]):(_vm.moduleType === 'qti_form')?_c('v-list-item-subtitle',{staticClass:"py-2"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:({
              path: 'stats_questions_answered',
              args: {
                count: item.metadata.answered,
                total: item.metadata.questions
              }
            }),expression:"{\n              path: 'stats_questions_answered',\n              args: {\n                count: item.metadata.answered,\n                total: item.metadata.questions\n              }\n            }"}]})]):_vm._e(),(_vm.generation === item.generation)?_c('v-btn',{staticClass:"d-flex",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.activate(item.generation)}}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('use_revision'),expression:"'use_revision'"}]})]):_vm._e()],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }