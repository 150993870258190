import Vue from "vue";
import Vuex from "vuex";

import createLogger from "vuex/dist/logger";

import { debugMode } from "@/debug";

Vue.use(Vuex);

const plugins = [];

if (debugMode) {
  plugins.push(createLogger());
}

export default new Vuex.Store({
  plugins,
  state: {
    templateCompiled: false,

    hidden: false,
    hiddenInit: false,

    moduleType: null,
    editorMetadata: { word_count: 0, char_count: 0 },
    revisionDialog: false,
    revision: null,
    readOnly: null,
    sectionLocked: false,

    token: null,

    responseValidators: {},
    cleanupCallbacks: [],

    requiredResponseIdentifiers: [],
    responseIdentifiers: [],
    assessmentItemResponseIdentifiers: {},

    responseValues: {},
    otherValues: {},

    speechSynthesis: false,
    speechRecognitionLanguage: null,
    spellCheckLanguage: null,

    allowPrint: false,

    bookmarks: []
  },
  getters: {
    bookmarks: state => state.bookmarks,

    speechSynthesis: state => state.speechSynthesis,

    speechRecognitionLanguage: state => state.speechRecognitionLanguage,

    spellCheckLanguage: state => state.spellCheckLanguage,

    allowPrint: state => state.allowPrint,

    templateCompiled: state => state.templateCompiled,

    hidden: state => state.hidden,

    hiddenInit: state => state.hiddenInit,

    token: state => state.token,

    moduleType: state => state.moduleType,

    revisionDialog: state => state.revisionDialog,

    revision: state => state.revision,

    readOnly: state => state.readOnly,

    sectionLocked: state => state.sectionLocked,

    cleanupCallbacks: state => state.cleanupCallbacks,

    responseIdentifiers: state => state.responseIdentifiers,

    assessmentItemResponseIdentifiers: state =>
      state.assessmentItemResponseIdentifiers,

    responseValue: state => responseIdentifier => {
      if (state.responseValues === undefined) return undefined;
      return state.responseValues[responseIdentifier];
    },
    otherValue: state => responseIdentifier => {
      if (state.otherValues === undefined) return undefined;
      return state.otherValues[responseIdentifier];
    },
    responseValues: state => state.responseValues,
    otherValues: state => state.otherValues,

    responseAnswered: (state, getters) => (responseIdentifier, parentId) => {
      const value = getters.responseValue(responseIdentifier);
      if (!value) {
        if (responseIdentifier === parentId) return false;
        //check the sub-ids
        const childrenIds =
          state.assessmentItemResponseIdentifiers[responseIdentifier];

        if (childrenIds && childrenIds.length > 0) {
          return childrenIds.every(id =>
            getters.responseAnswered(id, responseIdentifier)
          );
        }
        return false;
      } else if (Array.isArray(value)) {
        if (value.includes(responseIdentifier)) {
          // other value selected
          return !!getters.otherValue(responseIdentifier);
        }
        if (state.responseValidators[responseIdentifier]) {
          return state.responseValidators[responseIdentifier](value);
        }
        return value.length > 0;
      } else if (value === responseIdentifier) {
        // other value selected
        return !!getters.otherValue(responseIdentifier);
      }
      if (!!value && state.responseValidators[responseIdentifier]) {
        return state.responseValidators[responseIdentifier](value);
      } else return !!value;
    },

    responseValid: (state, getters) => responseIdentifier => {
      const required = getters.responseRequired(responseIdentifier);
      if (!required) return true;
      return getters.responseAnswered(responseIdentifier);
    },

    responseRequired: state => responseIdentifier =>
      state.requiredResponseIdentifiers.includes(responseIdentifier),

    valid: (state, getters) => {
      return state.requiredResponseIdentifiers.every(responseIdentifier => {
        return getters.responseValid(responseIdentifier);
      });
    },

    metadata: (state, getters) => {
      if (getters.moduleType !== "qti_editor") {
        return {
          questions: Object.keys(state.assessmentItemResponseIdentifiers)
            .length,
          answered: Object.keys(
            state.assessmentItemResponseIdentifiers
          ).filter(v => getters.responseAnswered(v)).length
        };
      } else return getters.editorMetadata;
    },

    editorMetadata: state => state.editorMetadata
  },
  mutations: {
    setRevisionDialog(state, revisionDialog) {
      state.revisionDialog = revisionDialog;
    },
    setModuleType(state, moduleType) {
      state.moduleType = moduleType;
    },
    setEditorMetadata(state, { metadata }) {
      state.editorMetadata = metadata;
    },

    setRevision(state, revision) {
      state.revision = revision;
    },

    setReadOnly(state, readOnly) {
      state.readOnly = readOnly;
    },

    setSectionLocked(state, sectionLocked) {
      state.sectionLocked = sectionLocked;
    },

    addCleanupCallback(state, f) {
      state.cleanupCallbacks.push(f);
    },

    setTemplateCompiled(state, templateCompiled) {
      state.templateCompiled = templateCompiled;
    },
    setHidden(state, hidden) {
      state.hidden = hidden;
    },
    setHiddenInit(state, hidden) {
      state.hiddenInit = hidden;
    },
    setToken(state, token) {
      state.token = token;
    },
    setResponseValues(state, values) {
      state.responseValues = values;
    },
    setOtherValues(state, values) {
      state.otherValues = values;
    },
    setSpeechSynthesisEnabled(state, enabled) {
      state.speechSynthesis = enabled;
    },
    setSpeechRecognitionLanguage(state, lang) {
      state.speechRecognitionLanguage = lang;
    },
    setSpellCheckLanguage(state, lang) {
      state.spellCheckLanguage = lang;
    },
    setAllowPrint(state, enabled) {
      state.allowPrint = enabled;
    },
    setResponseIdentifierValue(state, { responseIdentifier, value }) {
      state.responseValues = {
        ...state.responseValues,
        [responseIdentifier]: value
      };
    },
    setOtherValue(state, { responseIdentifier, value }) {
      state.otherValues = {
        ...state.otherValues,
        [responseIdentifier]: value
      };
    },
    setCustomValidator(state, { responseIdentifier, fn }) {
      state.responseValidators[responseIdentifier] = fn;
    },
    registerResponseIdentifier(
      state,
      { assessmentItemId, responseIdentifier }
    ) {
      state.responseIdentifiers.push(responseIdentifier);
      const item = state.assessmentItemResponseIdentifiers[assessmentItemId];
      if (item) item.push(responseIdentifier);
      else {
        Vue.set(state.assessmentItemResponseIdentifiers, assessmentItemId, [
          responseIdentifier
        ]);
      }
    },
    setResponseIdentifierRequired(state, { responseIdentifier }) {
      state.requiredResponseIdentifiers = [
        ...state.requiredResponseIdentifiers.filter(
          id => id !== responseIdentifier
        ),
        responseIdentifier
      ];
    },
    setBookmarks(state, bookmarks) {
      state.bookmarks = bookmarks;
    }
  }
});
