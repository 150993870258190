<template>
  <div data-tag="assessmentTest" :data-title="title" :identifier="identifier">
    <slot></slot>
  </div>
</template>

<script>
import { sendMessage } from "../plugins/messaging.js";
export default {
  name: "assessmentTest",

  props: {
    title: String,
    identifier: String
  },
  provide() {
    return {
      interactionInitialized: this.interactionInitialized
    };
  },

  data: () => ({
    partIds: [],
    toBeInitialized: 0
  }),

  methods: {
    parseParts() {
      const items = this.$el.querySelectorAll('div[data-tag="testPart"]');

      for (let i = 0; i < items.length; i++) {
        this.partIds.push(items[i].getAttribute("identifier"));
      }
    },
    interactionInitialized() {
      this.toBeInitialized--;
      if (this.toBeInitialized === 0) sendMessage("INITIALIZED");
    }
  },

  created() {
    if (this.$attrs["interaction-count"] !== undefined) {
      this.toBeInitialized = parseInt(this.$attrs["interaction-count"]);
    }
    if (this.toBeInitialized === 0) sendMessage("INITIALIZED");
  },

  mounted() {
    this.parseParts();
    if (!this.partIds.includes(this.$route.query.partId)) {
      this.$router
        .replace({
          query: { ...this.$route.query, partId: this.partIds[0] }
        })
        .catch(() => {});
    }
  }
};
</script>
