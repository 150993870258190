<template>
  <div
    data-tag="matchInteraction"
    class="matchInteraction"
    :data-response-identifier="responseIdentifier"
  >
    <table v-if="tabular">
      <tr>
        <th style="text-align: left;" v-text="promptText"></th>
        <th :key="option.text" v-for="option in options">
          <span v-text="option.text"></span>
          <speech-synthesis-btn class="visible" :text="option.text" />
        </th>
      </tr>

      <tr :key="row.identifier" v-for="row in rows">
        <td class="matchInteractionLabel">
          <span v-text="row.text"></span>
          <speech-synthesis-btn :text="row.text" />
        </td>

        <td :key="option.identifier" v-for="option in options">
          <div style="display: flex; justify-content: center;">
            <v-radio
              @click.native.prevent.stop.capture="
                onInput(row.identifier, option.identifier)
              "
              :class="{
                'radio-error':
                  rowError[row.identifier] || optionError[option.identifier]
              }"
              :ref="row.identifier"
              :name="row.identifier"
              :value="option.identifier"
              :data-option="option.identifier"
              v-if="optionType === 'radio'"
              class="ma-0 pa-0"
              :disabled="readOnly"
              hide-details
            />

            <v-checkbox
              @click.native.prevent.stop.capture="
                onInput(row.identifier, option.identifier)
              "
              :error="
                rowError[row.identifier] || optionError[option.identifier]
              "
              :ref="row.identifier"
              :disabled="readOnly"
              :input-value="
                values.some(
                  v =>
                    v.rowId === row.identifier &&
                    v.optionId === option.identifier
                )
              "
              v-else
              class="ma-0 pa-0"
              hide-details
            />
          </div>
        </td>
      </tr>
    </table>

    <div
      v-else
      class="d-flex justify-space-around qti-ordering slotted-data"
      flat
      ref="slotData"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

import responseIdentifierMixin from "@/mixins/responseIdentifierMixin";
import { mdiDrag } from "@mdi/js";

import { shuffleArray, logger } from "@/helpers";

export default {
  name: "matchInteraction",

  mixins: [responseIdentifierMixin],
  inject: ["addClearSelectionCb"],

  components: {
    speechSynthesisBtn
  },
  provide() {
    return {
      responseIdentifier: this.responseIdentifier,
      rows: () => this.rows,
      options: () => this.options,
      optionError: () => this.optionError,
      onDrop: this.onDrop,
      onMenuClick: this.onMenuClick,
      onClickRemove: this.onClickRemove,
      setDragEvent: this.setDragEvent,
      getDragEvent: this.getDragEvent,
      tabular: () => this.tabular
    };
  },

  data() {
    return {
      minAssociations: 0,
      maxAssociations: 1,
      shuffle: false,
      legacy: false,
      tabular: false,
      qtiClass: undefined,
      rowError: {},
      optionError: {},
      dragEvent: null,

      rows: [],
      options: [],
      promptText: "",
      values: [],
      icons: {
        mdiDrag
      }
    };
  },

  computed: {
    storedValue() {
      return this.$store.getters.responseValue(this.responseIdentifier);
    },

    optionType() {
      if (!this.rows) return "radio";

      // consider it to be a checkbox if it allows more than 1 matches
      if (this.legacy) {
        return this.options[0].matchMax === 1 ? "radio" : "checkbox";
      } else {
        return this.rows[0].matchMax === 1 ? "radio" : "checkbox";
      }
    }
  },

  methods: {
    setDragEvent(e) {
      this.dragEvent = e;
    },
    getDragEvent() {
      return this.dragEvent;
    },
    onDrop(optionId, e) {
      const rowId = e.dataTransfer.getData("text");
      if (!rowId) {
        logger.warn("nothing picked up?");
        return;
      }

      const option = this.options.find(v => v.identifier === optionId);
      if (
        option &&
        option.matchMax &&
        option.matchMax <=
          this.values.filter(v => v.optionId === optionId).length
      ) {
        return;
      }
      if (this.values.find(v => v.rowId === rowId && v.optionId === optionId)) {
        return;
      }
      this.values = [...this.values, { rowId, optionId }];
    },
    onMenuClick(rowId, optionId) {
      const option = this.options.find(v => v.identifier === optionId);
      if (!option) return;
      if (
        !this.values.find(v => v.rowId === rowId && v.optionId === optionId) &&
        (!option.matchMax ||
          option.matchMax >
            this.values.filter(v => v.optionId === optionId).length)
      ) {
        this.values = [...this.values, { rowId, optionId }];
      }
    },
    onClickRemove(rowId, optionId) {
      this.values = this.values.filter(
        v => v.rowId !== rowId || v.optionId !== optionId
      );
    },
    checkErrors() {
      if (!this.storedValue || !this.rows) return;
      //none of the below checks were applied originally
      //options<>rows, max default to 1 would break legacy ones
      if (this.legacy) {
        if (
          this.minAssociations > 0 &&
          this.values.length < this.minAssociations
        ) {
          this.rows.forEach(v => {
            this.$set(this.rowError, v.identifier, true);
          });
          return;
        }
        return (this.rowError = {});
      }

      if (
        this.maxAssociations > 0 &&
        this.values.length > this.maxAssociations
      ) {
        this.rows.forEach(v => {
          this.$set(this.rowError, v.identifier, true);
        });
        return;
      }
      for (let i = 0; i < this.rows.length; i++) {
        const row = this.rows[i];
        const rowCount = this.values.filter(v => v.rowId === row.identifier)
          .length;
        if (
          (row.matchMax > 0 && rowCount > row.matchMax) ||
          (row.matchMin > 0 && rowCount < row.matchMin)
        ) {
          this.$set(this.rowError, row.identifier, true);
        } else this.$delete(this.rowError, row.identifier);
      }
      for (let i = 0; i < this.options.length; i++) {
        const option = this.options[i];
        const optCount = this.values.filter(
          v => v.optionId === option.identifier
        ).length;
        if (
          (option.matchMax > 0 && optCount > option.matchMax) ||
          (option.matchMin > 0 && optCount < option.matchMin)
        ) {
          this.$set(this.optionError, option.identifier, true);
        } else this.$delete(this.optionError, option.identifier);
      }
    },
    clearSelection() {
      this.values = [];
      if (this.optionType === "radio") {
        this.rows.forEach(row => {
          let inputs = this.$refs[row.identifier];
          for (let i = 0; i < inputs.length; i++) inputs[i].isActive = false;
        });
      }
    },
    parseOptions() {
      if (this.$attrs["min-associations"] !== undefined) {
        this.minAssociations = parseInt(this.$attrs["min-associations"], 10);
      } else if (this.$attrs["minAssociations"] !== undefined) {
        this.minAssociations = parseInt(this.$attrs["minAssociations"], 10);
      }
      if (this.$attrs["max-associations"] !== undefined) {
        this.maxAssociations = parseInt(this.$attrs["max-associations"], 10);
      }

      this.shuffle = this.$attrs["shuffle"] === "true";
    },

    buildTable() {
      this.qtiClass = this.$el.getAttribute("class");
      if (this.qtiClass.includes("qti-match-tabular")) {
        this.tabular = true;
      } else if (this.qtiClass === "matchInteraction") {
        this.legacy = true;
        this.tabular = true;
      }

      const prompt = this.$el.querySelector("[data-tag=prompt]");
      this.promptText = prompt ? prompt.innerText.trim() : "";

      const [
        rowMatchSet,
        columnMatchSet
      ] = this.$refs.slotData.querySelectorAll("[data-tag=simpleMatchSet]");

      const rowElements = rowMatchSet.children;
      const optElements = columnMatchSet.children;

      const rows = [];
      const fixedRows = [];

      const options = [];
      const fixedOptions = [];

      for (let i = 0; i < rowElements.length; i++) {
        const element = rowElements[i];

        //it is supposed to be mandatory
        let matchMax = 0;
        if (element.hasAttribute("match-max")) {
          matchMax = parseInt(element.getAttribute("match-max"), 10);
        } else if (element.hasAttribute("matchMax")) {
          matchMax = parseInt(element.getAttribute("matchMax"), 10);
        }
        let matchMin = 0;
        if (element.hasAttribute("match-min")) {
          matchMin = parseInt(element.getAttribute("match-min"), 10);
        }

        const row = {
          text: element.innerText.trim(),
          html: "",
          identifier: element.getAttribute("identifier"),
          fixed: element.getAttribute("fixed") === "true",
          matchMax,
          matchMin,
          index: i
        };
        if (!this.tabular) {
          const slotdata = element.querySelector("div[data-slot-data]");
          row.html = slotdata.innerHTML;
          row.text = slotdata.innerText.trim();
        }
        if (row.fixed) {
          fixedRows.push([i, row]);
        } else {
          rows.push(row);
        }
      }

      for (let i = 0; i < optElements.length; i++) {
        const element = optElements[i];

        let matchMax = 0;
        if (element.hasAttribute("match-max")) {
          matchMax = parseInt(element.getAttribute("match-max"), 10);
        } else if (element.hasAttribute("matchMax")) {
          matchMax = parseInt(element.getAttribute("matchMax"), 10);
        }
        let matchMin = 0;
        if (element.hasAttribute("match-min")) {
          matchMin = parseInt(element.getAttribute("match-min"), 10);
        }

        const option = {
          text: element.innerText.trim(),
          html: "",
          identifier: element.getAttribute("identifier"),
          fixed: element.getAttribute("fixed") === "true",
          matchMax,
          matchMin,
          index: i
        };

        if (!this.tabular) {
          const slotdata = element.querySelector("div[data-slot-data]");
          option.html = slotdata.innerHTML;
          option.text = slotdata.innerText.trim();
        }

        if (option.fixed) {
          fixedOptions.push([i, option]);
        } else {
          options.push(option);
        }
      }

      if (this.shuffle) {
        shuffleArray(rows);
        shuffleArray(options);
      }

      fixedRows.forEach(([index, row]) => {
        rows.splice(index, 0, row);
      });

      fixedOptions.forEach(([index, option]) => {
        options.splice(index, 0, option);
      });

      if (!this.tabular && this.shuffle) {
        for (let i = 0; i < rows.length; i++) {
          rowMatchSet.appendChild(rowElements[rows[i].index]);
        }
        for (let i = 0; i < options.length; i++) {
          columnMatchSet.appendChild(optElements[options[i].index]);
        }
      }

      this.rows = rows;
      this.options = options;
    },

    onInput(rowId, optionId) {
      if (this.optionType === "radio") {
        const inputs = this.$refs[rowId];
        for (let i = 0; i < inputs.length; i++) {
          const radio = inputs[i];
          if (radio.value !== optionId) {
            radio.isActive = false;
          } else if (radio.value === optionId) {
            radio.isActive = true;
            this.values = [
              ...this.values.filter(v => v.rowId !== rowId),
              { rowId, optionId }
            ];
          }
        }
      } else {
        const valueIndex = this.values.findIndex(
          v => v.rowId === rowId && v.optionId === optionId
        );
        if (valueIndex >= 0) {
          this.values = this.values.filter(
            v => v.rowId !== rowId || v.optionId !== optionId
          );
        } else {
          this.values = [...this.values, { rowId, optionId }];
        }
      }
    },

    fixRadioSelection() {
      this.rows.forEach(row => {
        const inputs = this.$refs[row.identifier];
        const selected = this.values.find(v => v.rowId === row.identifier);

        for (let i = 0; i < inputs.length; i++) {
          const radio = inputs[i];
          if (!selected || radio.value !== selected.optionId) {
            radio.isActive = false;
          } else {
            radio.isActive = true;
          }
        }
      });
    }
  },

  watch: {
    values(values) {
      this.commitValue(values);
      this.checkErrors();
    },

    revision(revision) {
      if (!revision || !revision.form) return;
      this.clearSelection();
      if (revision.form[this.responseIdentifier]) {
        this.values = revision.form[this.responseIdentifier];
        if (this.optionType === "radio") {
          this.$nextTick(this.fixRadioSelection);
        }
      }
    }
  },

  created() {
    this.parseOptions();

    this.registerResponseIdentifier();
    this.setResponseIdentifierRequired(this.minAssociations > 0);

    if (this.storedValue != null) {
      this.values = this.storedValue;
    }
  },

  mounted() {
    this.buildTable();
    if (this.storedValue != null) this.checkErrors();

    if (this.tabular && this.optionType === "radio") {
      // must run after table is built
      this.$nextTick(() => {
        this.fixRadioSelection();
      });
      this.addClearSelectionCb(this.clearSelection);
    }
    if (this.minAssociations > 0) {
      this.$store.commit("setCustomValidator", {
        responseIdentifier: this.responseIdentifier,
        fn: () => {
          return !(
            Object.keys(this.rowError).length ||
            Object.keys(this.optionError).length
          );
        }
      });
    }

    this.interactionInitialized();
  }
};
</script>
<style>
.radio-error .v-input--selection-controls__input .v-icon {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
.matchInteraction {
  overflow-y: hidden;
  overflow-x: auto;
}
</style>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
}

tr {
  border-top: 1px solid #ccc;
}

tr:first-child {
  border: none;
}

tr:nth-child(2n) {
  background-color: #fafafafa;
}
</style>
