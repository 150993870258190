<template>
  <div
    class="assessmentSection"
    data-tag="assessmentSection"
    :data-title="title"
    :identifier="identifier"
    v-show="showComponent"
  >
    <div v-show="!$route.query.overview">
      <h1 class="mt-4 text-h4">
        <span v-text="title"></span>
        <speech-synthesis-btn :text="title" />
      </h1>
      <span class="error--text">* <span v-t="'answer_required'"></span></span>

      <div ref="items">
        <slot></slot>
      </div>
    </div>

    <v-layout
      v-if="$route.query.display === 'page' && !$route.query.overview"
      :column="$vuetify.breakpoint.xsOnly"
      justify-space-between
      :align-center="!$vuetify.breakpoint.xsOnly"
    >
      <v-layout shrink align-center>
        <v-btn @click="toggleBookmark" icon :disabled="readOnly">
          <v-icon
            v-if="bookmarks.includes($route.query.itemId)"
            v-text="icons.mdiBookmark"
            color="primary"
          />
          <v-icon v-else v-text="icons.mdiBookmarkOutline" />
        </v-btn>

        <v-btn
          class="px-2 ml-1"
          color="grey darken-3"
          :disabled="sectionLocked"
          text
          :to="{ query: { ...$route.query, overview: true } }"
        >
          <v-icon v-text="icons.mdiTableOfContents" class="mr-1" />
          <span
            v-t="{
              path: 'page_num',
              args: {
                current: currentItemIndex + 1,
                max: assessmentItems.length
              }
            }"
          ></span>
        </v-btn>
      </v-layout>

      <v-layout
        :shrink="!$vuetify.breakpoint.xsOnly"
        :pt-2="$vuetify.breakpoint.xsOnly"
        pb-4
        align-center
      >
        <v-btn
          @click="prevItem"
          color="primary"
          depressed
          :disabled="currentItemIndex === 0 || sectionLocked"
          :class="{ 'mr-1': true, grow: $vuetify.breakpoint.xsOnly }"
        >
          <span v-t="'previous'"></span>
        </v-btn>

        <v-btn
          @click="nextItem"
          color="primary"
          depressed
          :disabled="sectionLocked"
          :class="{ 'ml-1': true, grow: $vuetify.breakpoint.xsOnly }"
        >
          <span v-t="'next'"></span>
        </v-btn>
      </v-layout>
    </v-layout>

    <template v-if="$route.query.overview">
      <h1 class="my-3 text-h4" v-t="'overview'"></h1>

      <v-divider />

      <v-simple-table style="background: #fafafa;">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" v-t="'question_answered'"></th>
              <th class="text-left" v-t="'title'" style="width: 100%;"></th>
              <th class="text-center" v-t="'bookmarked'"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="gotoQuestion(item.identifier)"
              v-for="item in assessmentItems"
              :key="item.identifier"
              class="overview-row"
            >
              <td class="text-center">
                <v-icon
                  v-if="!subInteractions[item.identifier]"
                  v-text="icons.mdiInformationOutline"
                />
                <v-icon
                  v-else-if="responseAnswered(item.identifier)"
                  color="success"
                  v-text="icons.mdiCheckboxOutline"
                />
                <v-icon
                  v-else
                  :color="!responseValid(item.identifier) ? 'error' : undefined"
                  v-text="icons.mdiCheckboxBlankOutline"
                />
              </td>
              <td>
                <span v-text="item.title"></span>
                <span
                  v-if="!responseValid(item.identifier)"
                  class="error--text"
                >
                  *
                </span>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="bookmarks.includes(item.identifier)"
                  v-text="icons.mdiBookmark"
                  color="primary"
                />
                <v-icon v-else disabled v-text="icons.mdiBookmarkOutline" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiBookmark,
  mdiBookmarkOutline,
  mdiCheckboxOutline,
  mdiCheckboxBlankOutline,
  mdiInformationOutline,
  mdiTableOfContents,
  mdiHelpCircleOutline
} from "@mdi/js";

import { shuffleArray } from "@/helpers";

import displayMixin from "@/mixins/displayMixin";

export default {
  name: "assessmentSection",

  mixins: [displayMixin],

  props: {
    title: String,
    identifier: String
  },

  components: {
    speechSynthesisBtn
  },

  data: () => ({
    assessmentItems: [],

    icons: {
      mdiChevronLeft,
      mdiChevronRight,
      mdiBookmark,
      mdiBookmarkOutline,
      mdiCheckboxOutline,
      mdiCheckboxBlankOutline,
      mdiInformationOutline,
      mdiTableOfContents,
      mdiHelpCircleOutline
    }
  }),

  computed: {
    bookmarks() {
      return this.$store.getters.bookmarks;
    },

    responseAnswered() {
      return this.$store.getters.responseAnswered;
    },

    responseValid() {
      return this.$store.getters.responseValid;
    },

    currentItem() {
      return this.assessmentItems.find(
        item => item.identifier === this.$route.query.itemId
      );
    },

    currentItemIndex() {
      return this.assessmentItems.findIndex(
        item => item.identifier === this.$route.query.itemId
      );
    },

    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    },

    sectionLocked() {
      return this.$store.getters.sectionLocked;
    },

    subInteractions() {
      return this.$store.getters.assessmentItemResponseIdentifiers;
    }
  },

  methods: {
    shuffle() {
      const elements = Array.from(
        this.$refs.items.querySelectorAll('div[data-tag="assessmentItem"]')
      );

      shuffleArray(elements);
      elements.forEach(el => this.$refs.items.appendChild(el));
    },

    toggleBookmark() {
      const itemId = this.$route.query.itemId;

      let bookmarks;
      if (this.bookmarks.includes(itemId)) {
        bookmarks = this.bookmarks.filter(id => id !== itemId);
      } else {
        bookmarks = [...this.bookmarks, itemId];
      }

      this.$store.commit("setBookmarks", bookmarks);
    },

    gotoQuestion(itemId) {
      const query = { ...this.$route.query, itemId };
      delete query.overview;
      this.$router.replace({ query });
    },

    parseItems() {
      const items = this.$el.querySelectorAll('div[data-tag="assessmentItem"]');
      for (let i = 0; i < items.length; i++) {
        this.assessmentItems.push({
          identifier: items[i].getAttribute("identifier"),
          title: items[i].getAttribute("data-title")
        });
      }
    },

    onNavigation(to) {
      if (to.query.sectionId === this.identifier) {
        if (!this.assessmentItems.some(i => i.identifier === to.query.itemId)) {
          const itemId = this.assessmentItems[0].identifier;
          this.$router
            .replace({ query: { ...to.query, itemId } })
            .catch(() => {});
        }
      }
    },

    prevItem() {
      if (this.currentItemIndex > 0) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            itemId: this.assessmentItems[this.currentItemIndex - 1].identifier
          }
        });
      }
    },

    nextItem() {
      if (this.currentItemIndex + 1 < this.assessmentItems.length) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            itemId: this.assessmentItems[this.currentItemIndex + 1].identifier
          }
        });
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            overview: true
          }
        });
      }
    }
  },

  watch: {
    $route(to, from) {
      if (from && to.query.sectionId === from.query.sectionId) return;
      this.onNavigation(to);
    }
  },

  mounted() {
    const ordering = this.$refs.items.querySelector('div[data-tag="ordering"]');
    if (ordering && ordering.getAttribute("shuffle") === "true") {
      this.shuffle();
    }

    // parse assessmentItems order after shuffle
    this.parseItems();
    this.onNavigation(this.$route);
  }
};
</script>

<style>
.overview-row {
  cursor: pointer;
}
</style>
