<template>
  <v-card
    data-tag="assessmentItem"
    :data-title="title"
    :identifier="identifier"
    class="assessmentItem my-4"
    style="overflow-x: auto;"
    v-show="showComponent"
  >
    <v-card-title class="assessment-title">
      <h2 class="text-h5">
        <span v-text="title"></span>
        <span class="red--text ml-1" v-if="required">*</span>
        <speech-synthesis-btn :text="title" />
      </h2>
    </v-card-title>
    <slot></slot>
    <v-card-actions v-if="clearButton" class="justify-end">
      <v-btn text @click="clearSelection" :disabled="readOnly"
        ><span v-t="'clear_selection'"
      /></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import speechSynthesisBtn from "@/components/speechSynthesisBtn";

import displayMixin from "@/mixins/displayMixin";

export default {
  name: "assessmentItem",

  components: {
    speechSynthesisBtn
  },
  mixins: [displayMixin],
  props: {
    title: String,
    identifier: String
  },
  provide() {
    return {
      addClearSelectionCb: this.addClearSelectionCb,
      assessmentItemId: this.identifier
    };
  },
  data() {
    return {
      clearable: undefined,
      clearCallbacks: []
    };
  },
  methods: {
    clearSelection() {
      for (const cb of this.clearCallbacks) cb();
    },
    addClearSelectionCb(cb) {
      this.clearCallbacks.push(cb);
    }
  },
  computed: {
    readOnly() {
      return this.$store.getters.readOnly || this.$store.getters.revisionDialog;
    },
    required() {
      if (this.$store.getters.responseRequired(this.identifier)) return true;
      const childrenIds = this.$store.getters.assessmentItemResponseIdentifiers[
        this.identifier
      ];
      if (!childrenIds) return false;
      for (let i = 0; i < childrenIds.length; i++) {
        if (this.$store.getters.responseRequired(childrenIds[i])) {
          return true;
        }
      }
      return false;
    },
    clearButton() {
      if (!(this.clearable && this.clearCallbacks.length)) return false;
      const v = this.$store.getters.responseValue(this.identifier);
      if (Array.isArray(v)) return v.length > 0;
      else return v !== null && v !== "" && v !== undefined;
    }
  },

  mounted() {
    this.clearable = this.$attrs["data-clearable"] === "true";
  }
};
</script>
