<template>
  <component :is="compiled"></component>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
  name: "Assessment",

  data: () => ({
    compiled: null
  }),

  computed: {
    package() {
      return this.$route.params.package;
    },

    identifier() {
      return this.$route.params.identifier;
    }
  },

  methods: {
    async getTemplate() {
      let numRetries = 0;
      let err;

      while (numRetries < 3) {
        try {
          const { data } = await axios.get(
            `/qti/v1/static/${this.package}/${this.identifier}.vxml`,
            {
              responseType: "text",
              timeout: 60000,
              headers: {
                authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          );

          return data;
        } catch (error) {
          err = error;
          // eslint-disable-next-line
          console.log(error);
          numRetries += 1;
        }
      }

      if (err && err.message) {
        return `<p>Something went wrong (${err.message})</p>`;
      } else {
        return "<p>Critical error</p>";
      }
    }
  },

  async created() {
    const template = await this.getTemplate();
    this.compiled = Vue.compile(template);

    this.$store.commit("setTemplateCompiled", true);
  },
  beforeRouteLeave(to, from, next) {
    if (!to.params.forced && to.name !== "assessment") {
      next(false);
    } else next();
  }
};
</script>
